import { extendTheme } from "@chakra-ui/react";
import { breakpoints } from "./breakpoints";
import { convertHexToRgba } from "../../../utils/convertHexToRgba";
import {
  LIGHT_THEME_BACKGROUND,
  LIGHT_THEME_DANGER,
  LIGHT_THEME_GRAY,
  LIGHT_THEME_INFO,
  LIGHT_THEME_LINK,
  LIGHT_THEME_PRIMARY,
  LIGHT_THEME_STAR,
  LIGHT_THEME_SUCCESS,
  LIGHT_THEME_TEXT,
  LIGHT_THEME_WARNING,
} from "../config";

export const lightModeTheme = extendTheme({
  styles: {
    global: {
      body: {
        background: LIGHT_THEME_BACKGROUND,
      },
    },
  },
  colors: {
    custom: {
      background: LIGHT_THEME_BACKGROUND,
      danger: LIGHT_THEME_DANGER,
      gray: LIGHT_THEME_GRAY,
      info: LIGHT_THEME_INFO,
      link: LIGHT_THEME_LINK,
      primary: LIGHT_THEME_PRIMARY,
      star: LIGHT_THEME_STAR,
      success: LIGHT_THEME_SUCCESS,
      text: LIGHT_THEME_TEXT,
      warning: LIGHT_THEME_WARNING,
      selection: convertHexToRgba(LIGHT_THEME_PRIMARY.main, 0.3),
    },
  },
  breakpoints,
});
