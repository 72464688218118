import { Route } from "@tanstack/react-location";
import { AuthenticatedUserPermissionWrapper } from "components/common/AuthenticatedUserPermissionWrapper";
import { GuestUserPermissionWrapper } from "components/common/GuestUserPermissionWrapper";
import { LocalEnvPermissionWrapper } from "components/common/LocalEnvPermissionWrapper";
import { ErrorPage } from "components/pages/ErrorPage";
import { ManagementPage } from "components/pages/ManagementPage";
import { PointStorePage } from "components/pages/PointStorePage";
import { TopPage } from "components/pages/TopPage";
import { NotFoundPage } from "../../components/pages/NotFoundPage";
import { SignInPage } from "../../components/pages/SignInPage";

export const routes: Route[] = [
  {
    path: "/",
    element: (
      <GuestUserPermissionWrapper>
        <TopPage />
      </GuestUserPermissionWrapper>
    ),
  },
  {
    path: "/signin",
    element: (
      <GuestUserPermissionWrapper>
        <SignInPage />
      </GuestUserPermissionWrapper>
    ),
  },
  {
    path: "/store",
    element: (
      <AuthenticatedUserPermissionWrapper redirect="/store">
        <PointStorePage />
      </AuthenticatedUserPermissionWrapper>
    ),
  },
  {
    path: "/management",
    element: (
      <LocalEnvPermissionWrapper>
        <ManagementPage />
      </LocalEnvPermissionWrapper>
    ),
  },
  {
    path: "/error",
    element: (
      <GuestUserPermissionWrapper>
        <ErrorPage />
      </GuestUserPermissionWrapper>
    ),
  },
  {
    path: "/*",
    element: (
      <GuestUserPermissionWrapper>
        <NotFoundPage />
      </GuestUserPermissionWrapper>
    ),
  },
];
