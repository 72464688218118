import { LocalImg } from "components/uiParts/img/LocalImg";
import { Text } from "components/uiParts/text/Text";
import React from "react";
import { LOCAL_IMAGE } from "constants/localImage";
import { ImgProps, TextProps } from "@chakra-ui/react";

export type Props = {
  localImageSrcKey: keyof typeof LOCAL_IMAGE;
  text?: string;
  imgProps?: ImgProps;
  textProps?: TextProps;
};

/**
 * ローカル画像と文字列でコンポーネントを生成
 * @param localImageSrcKey ローカル画像のsrc
 * @param text 表示するtext
 * @param imgProps ImgProps
 * @param textProps TextProps
 * @returns 画像付き文字列
 */
export const SignInIconAndText: React.FC<Props> = ({
  localImageSrcKey,
  text,
  imgProps,
  textProps,
}) => {
  return (
    <>
      <LocalImg
        localImageSrcKey={localImageSrcKey}
        imageProps={{ width: { base: "80px", sm: "120px" }, ...imgProps }}
      />

      {/* Textが空の時は表示しない */}
      {text ? (
        <Text
          textProps={{
            fontSize: { base: "32px", sm: "48px" },
            fontWeight: "700",
            ...textProps,
          }}
        >
          {text}
        </Text>
      ) : null}
    </>
  );
};
