/**
 * APIの認証ヘッダーを生成
 * @param token トークン
 * @returns ヘッダーオブジェクト
 */
export const generateAuthorizationHeader = (token: string) => {
  return {
    AUTHORIZATION: `Token ${token}`,
  };
};
