import { MaintenancePage } from "components/pages/MaintenancePage";
import { apiManagementSelectors } from "plugins/reduxToolkit/apiManagement";
import React from "react";
import { useAppSelector } from "../../plugins/reduxToolkit/hooks";

export type Props = {
  children: React.ReactNode;
};

/**
 * どんなユーザでも閲覧できる権限を付与する
 * @param children children
 * @returns Wrapper
 */
export const MaintenanceWrapper: React.FC<Props> = ({ children }) => {
  const isMaintenanceMode = useAppSelector(
    apiManagementSelectors.isMaintenanceModeSelector
  );
  if (isMaintenanceMode) {
    return <MaintenancePage />;
  } else {
    return <>{children}</>;
  }
};
