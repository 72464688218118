import { MenuGroup, MenuGroupProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  menuGroupProps?: MenuGroupProps;
  children: ReactNode;
};

/**
 * メニューグループ
 * @param menuGroupProps MenuGroupProps
 * @param children  ReactNode
 * @returns メニューグループ
 */
export const BaseMenuGroup: React.FC<Props> = ({
  menuGroupProps,
  children,
}) => {
  return (
    <MenuGroup
      _selection={{ background: "custom.selection" }}
      color={"custom.text.light"}
      fontSize={"12px"}
      backgroundColor={"custom.background"}
      title={"title"}
      {...menuGroupProps}
    >
      {children}
    </MenuGroup>
  );
};
