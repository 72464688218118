import { SpinnerProps } from "@chakra-ui/react";
import React from "react";
import { BaseSpinner } from "./BaseSpinner";

export type Props = {
  spinnerProps?: SpinnerProps;
};
/**
 * スピナー
 * @param spinnerProps SpinnerProps
 * @returns スピナー
 */
export const Spinner: React.FC<Props> = ({ spinnerProps }) => {
  return (
    <BaseSpinner
      spinnerProps={{
        ...spinnerProps,
      }}
    />
  );
};
