import { useNavigate } from "@tanstack/react-location";
import { PrimaryButton } from "components/uiParts/button/PrimaryButton";
import { RowCenterFlex } from "components/uiParts/flex/RowCenterFlex";
import { RowSpaceBetweenFlex } from "components/uiParts/flex/RowSpaceBetweenFlex";
import { LocalImg } from "components/uiParts/img/LocalImg";
import { WORD } from "constants/word";
import { authSelectors } from "plugins/reduxToolkit/auth";
import { useAppSelector } from "plugins/reduxToolkit/hooks";
import React from "react";
import { clickableStyle } from "components/uiParts/styles/hover";
import { StoreMenu } from "./StoreMenu";
import { AccountMenu } from "./AccountMenu";
import { Box } from "components/uiParts/box/Box";

export const HEADER_HEIGHT = "60px";

export type Props = {
  hideSignInButton?: boolean;
};

/**
 * ヘッダー
 * サインインの有無によって表記を変えている
 * @returns ヘッダー
 */
export const Header: React.FC<Props> = ({ hideSignInButton }) => {
  if (!hideSignInButton) {
    hideSignInButton = false;
  }
  const signedIn = useAppSelector(authSelectors.signedInSelector);
  const navigate = useNavigate();

  return (
    <Box>
      <RowSpaceBetweenFlex
        flexProps={{
          width: "100%",
          height: HEADER_HEIGHT,
          paddingX: "16px",
          align: "center",
          boxShadow: "base",
          position: "fixed",
          zIndex: 3,
          top: 0,
          left: 0,
        }}
      >
        <LocalImg
          localImageSrcKey="LOGO_AND_ICON_SIZE_300"
          imageProps={{
            width: { base: "140px", sm: "200px" },
            onClick: () => navigate({ to: "/" }),
            _hover: clickableStyle,
          }}
        />
        {signedIn ? (
          <>
            <RowCenterFlex flexProps={{ align: "center" }}>
              <RowCenterFlex>
                <StoreMenu />
                <AccountMenu />
              </RowCenterFlex>
            </RowCenterFlex>
          </>
        ) : (
          <>
            {hideSignInButton ? null : (
              <PrimaryButton
                title={WORD.SIGN_IN_UPPER}
                buttonProps={{
                  onClick: () => navigate({ to: "/signin" }),
                }}
              />
            )}
          </>
        )}
      </RowSpaceBetweenFlex>
      {/* 重なり防止のためヘッダーの高さ分の余白をつける */}
      <Box boxProps={{ marginTop: HEADER_HEIGHT }}></Box>
    </Box>
  );
};
