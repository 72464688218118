import React from "react";
import { ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { ThemeType } from "./themes";

export type Props = {
  theme: ThemeType;
  children: ReactNode;
};

/**
 * ChakraUIプロバイダー
 * @param children ReactNode
 */
export const ChakraUiProvider: React.FC<Props> = ({ theme, children }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
