import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchCastDisplaysAsyncThunk } from "./asyncThunks";
import { ACTION_TYPE } from "./reducers";
import { castSlice } from "./slice";
import { CastStateType } from "./states";

/**
 * ExtraReducersを定義
 */
export const extraReducers = (
  builder: ActionReducerMapBuilder<CastStateType>
) => {
  builder.addCase(fetchCastDisplaysAsyncThunk.fulfilled, (state, action) => {
    castSlice.caseReducers.setCastDisplaysStateAction(state, {
      type: ACTION_TYPE.SET_PRICING_GROUPS_STATE_ACTION,
      payload: action.payload,
    });
  });
};
