import { ButtonProps } from "@chakra-ui/react";
import React from "react";
import { BaseButton } from "./BaseButton";

export type Props = {
  title: string;
  buttonProps?: ButtonProps;
};

/**
 * 主要なボタン
 * @param buttonProps ButtonProps;
 * @param title ボタンタイトル
 * @returns 主要なボタン
 */

export const PrimaryButton: React.FC<Props> = ({ buttonProps, title }) => {
  return (
    <BaseButton
      buttonProps={{
        _hover: { background: "custom.primary.light" },
        _active: { background: "custom.primary.main" },
        color: "white",
        bg: "custom.primary.main",
        ...buttonProps,
      }}
      title={title}
    />
  );
};
