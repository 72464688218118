import { AvatarProps } from "@chakra-ui/avatar";
import React from "react";
import { BaseAvatar } from "./BaseAvatar";

export type Props = {
  avatarProps?: AvatarProps;
};
/**
 * アバター画像
 * @param avatarProps AvatarProps
 * @returns div要素
 */
export const Avatar: React.FC<Props> = ({ avatarProps }) => {
  return <BaseAvatar avatarProps={{ ...avatarProps }}></BaseAvatar>;
};
