import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SENTENCE } from "constants/sentence";

const validationSchema = Yup.object().shape({
  email: Yup.string().required(SENTENCE.VALIDATION.EMAIL.REQUIRED_ERROR),
  password: Yup.string().required(SENTENCE.VALIDATION.PASSWORD.REQUIRED_ERROR),
});

export const SignInFormResolver = yupResolver(validationSchema);
