import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./reducers";
import { initialState } from "./states";
import { extraReducers } from "./extraReducers";

/**
 * authSliceを定義
 */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers,
  extraReducers,
});
