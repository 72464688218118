import { ThemeStore, LIGHT, DARK } from "./store";
import {
  ThemeAction,
  CHANGE_DARK_THEME_ACTION,
  CHANGE_LIGHT_THEME_ACTION,
  NEXT_THEME_ACTION,
} from "./actions/type";

/**
 * テーマの切り替えを行うReducer
 * @param state 現在のステート
 * @param action 実行種別
 * @returns 実行後のステート
 */
export const themeReducer = (
  state: ThemeStore,
  action: ThemeAction
): ThemeStore => {
  switch (action) {
    case CHANGE_LIGHT_THEME_ACTION:
      return { mode: LIGHT };

    case CHANGE_DARK_THEME_ACTION:
      return { mode: DARK };

    case NEXT_THEME_ACTION:
      if (state.mode === LIGHT) {
        return { mode: DARK };
      } else {
        return { mode: LIGHT };
      }

    default:
      return { mode: state.mode };
  }
};
