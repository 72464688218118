import { MenuList, MenuListProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  menuListProps?: MenuListProps;
  children: ReactNode;
};

/**
 * メニューリスト
 * @param menuListProps MenuListProps
 * @param children  ReactNode
 * @returns メニューリスト
 */
export const BaseMenuList: React.FC<Props> = ({ menuListProps, children }) => {
  return (
    <MenuList
      _selection={{ background: "custom.selection" }}
      color={"custom.text.main"}
      backgroundColor={"custom.background"}
      boxShadow={"lg"}
      {...menuListProps}
    >
      {children}
    </MenuList>
  );
};
