import { Box } from "components/uiParts/box/Box";
import { ImageAndTextCenter } from "components/unitParts/imageAndTextSimpleParts/ImageAndTextCenter";
import React from "react";
import { SENTENCE } from "../../constants/sentence";

/**
 * メンテナンステンプレート
 * @returns メンテナンステンプレート
 */
export const MaintenanceTemplate: React.FC = () => {
  return (
    <Box>
      <ImageAndTextCenter
        localImageSrcKey="MAINTENANCE_BLACK_600"
        text={SENTENCE.MAINTENANCE.MESSAGE}
      />
    </Box>
  );
};
