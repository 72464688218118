import { Box } from "components/uiParts/box/Box";
import { PrimaryButton } from "components/uiParts/button/PrimaryButton";
import { Text } from "components/uiParts/text/Text";
import React from "react";
import { request } from "plugins/axios/request";
import { useState } from "react";
import { Header } from "components/unitParts/header/Header";

/**
 * 管理用ページ(DEBUGなど)
 * 開発段階ではDEBUGように使用するためTemplate, uiUnits等を実装しない
 * ローカルでのみ動く
 * @returns ManagementPage
 */
export const ManagementPage: React.FC = () => {
  const [apiConnectionTestResult] = useState<string>("");

  return (
    <Box>
      <Header />
      <Text>
        {"接続先API情報: " + process.env.REACT_APP_ONERECO_API_BASE_URL ??
          "NotFound"}
      </Text>

      <PrimaryButton
        title={
          apiConnectionTestResult
            ? `API Connection Test: ${apiConnectionTestResult}`
            : "API Connection Test"
        }
        buttonProps={{
          mt: "4",
          onClick: () =>
            request<object, object, object>(
              "GET",
              "MASTER_BAD_REVIEW_OUTLINES"
            ),
        }}
      />
    </Box>
  );
};
