import React, { ReactNode, useReducer } from "react";
import { themeReducer } from "./reducer";
import { LIGHT, ThemeStore } from "./store";
import { ThemeContext } from "./context";

const initialState: ThemeStore = { mode: LIGHT };

export type Props = {
  children: ReactNode;
};

/**
 * テーマ環境を提供するProviderを定義
 * @param props.children 小要素
 * @returns テーマ環境でラッピングした要素
 */
export const ThemeContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, initialState);

  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {children}
    </ThemeContext.Provider>
  );
};
