import { extendTheme } from "@chakra-ui/react";
import { breakpoints } from "./breakpoints";
import {
  DARK_THEME_BACKGROUND,
  DARK_THEME_DANGER,
  DARK_THEME_GRAY,
  DARK_THEME_INFO,
  DARK_THEME_LINK,
  DARK_THEME_PRIMARY,
  DARK_THEME_STAR,
  DARK_THEME_SUCCESS,
  DARK_THEME_TEXT,
  DARK_THEME_WARNING,
} from "../config";
import { convertHexToRgba } from "utils/convertHexToRgba";

export const darkModeTheme = extendTheme({
  styles: {
    global: {
      body: {
        background: DARK_THEME_BACKGROUND,
      },
    },
  },
  colors: {
    custom: {
      background: DARK_THEME_BACKGROUND,
      danger: DARK_THEME_DANGER,
      gray: DARK_THEME_GRAY,
      info: DARK_THEME_INFO,
      link: DARK_THEME_LINK,
      primary: DARK_THEME_PRIMARY,
      star: DARK_THEME_STAR,
      success: DARK_THEME_SUCCESS,
      text: DARK_THEME_TEXT,
      warning: DARK_THEME_WARNING,
      selection: convertHexToRgba(DARK_THEME_PRIMARY.main, 0.3),
    },
  },
  breakpoints,
});
