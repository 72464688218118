export const WORD = {
  EMAIL: "メールアドレス",
  PASSWORD: "パスワード",
  SIGN_IN_UPPER_CAMEL: "Sign In",
  SIGN_IN_UPPER: "SIGN IN",
  SIGN_IN: "Sign In",
  FIRST_POINT_NAME_JPN: "有償ポイント",
  SECOND_POINT_NAME_JPN: "応援ポイント",
  FIRST_POINT_NAME_PT: "有償pt",
  SECOND_POINT_NAME_PT: "応援pt",
  POINT_STORE_LIST_HEADER_WITH_POINT: "獲得ポイント",
  POINT_STORE_LIST_HEADER_WITH_PRICE: "購入金額",
  HOW_TO: "HOW TO",
  STORE: "STORE",
  TO_FIRST_POINT_STORE: "有償ポイントを購入",
  ACCOUNT: "ACCOUNT",
  SIGN_OUT: "SIGN OUT",
  AVAILABLE_POINT: "所持ポイント",
  USER_INFO: "アカウント",
  ERR_NETWORK_RESPONSE_CODE: "ERR_NETWORK",
  ERR_NETWORK_RESPONSE_MESSAGE: "Network Error",
};
