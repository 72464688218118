import { BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseBox } from "./BaseBox";

export type Props = {
  children?: ReactNode;
  boxProps?: BoxProps;
};
/**
 * 要素を単に分割するために使用する
 * @param boxProps BoxProps
 * @param children ReactNode
 * @returns div要素
 */
export const Box: React.FC<Props> = ({ children, boxProps }) => {
  return <BaseBox boxProps={{ ...boxProps }}>{children}</BaseBox>;
};
