import { InputProps } from "@chakra-ui/react";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { BaseInput } from "./BaseInput";

export type Props = {
  inputProps?: InputProps;
  formRegisters?: UseFormRegisterReturn;
};
/**
 * メール用のinput要素
 * @param inputProps InputProps
 * @param formRegisters formバリデーション対象登録用
 * @returns input (type:email)
 */
export const EmailInput: React.FC<Props> = ({ inputProps, formRegisters }) => {
  return (
    <BaseInput
      inputProps={{
        size: "lg",
        type: "email",
        variant: "flushed",
        placeholder: "Email",
        name: "email",
        ...inputProps,
      }}
      formRegisters={formRegisters}
    ></BaseInput>
  );
};
