import { createSelector } from "reselect";
import { shuffleArray } from "utils/shuffle";
import { RootState } from "../store";
import { CastDisplaysStateType, CastStateType } from "./states";

/**
 * castステートを全て取得する
 * @param state state
 * @returns cast
 */
export const castSelector = (state: RootState): CastStateType => state.cast;

/**
 * 表示用キャスト一覧を取得
 */
export const castDisplays = createSelector(
  castSelector,
  (cast): CastDisplaysStateType => {
    return cast.displays;
  }
);

/**
 * 表示用キャスト一覧をランダムに並び替えて取得
 */
export const castDisplaysRandomSorted = createSelector(
  castSelector,
  (cast): CastDisplaysStateType => {
    return shuffleArray(cast.displays);
  }
);
