import { TextProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseText } from "./BaseText";

export type Props = {
  textProps?: TextProps;
  children: ReactNode;
};
/**
 * 主に使用する文字
 * @param textProps TextProps
 * @param children 文字
 * @returns 文字
 */
export const Text: React.FC<Props> = ({ textProps, children }) => {
  return (
    <BaseText
      textProps={{
        ...textProps,
      }}
    >
      {children}
    </BaseText>
  );
};
