import { awsRum } from "log/config";
import { EventTypeType } from "log/eventType";
import { EventDataType } from "log/eventData";
import { sendLogLocal } from "./sendLogLocal";

/**
 * AWSCloudWatchへログを送信する
 * ローカル環境の場合はコンソール出力する
 */
export const sendLog = async (
  eventType: EventTypeType,
  eventData?: EventDataType
) => {
  const env = process.env.REACT_APP_ENV ?? "";
  if (env === "DEV" || env === "PRD") {
    awsRum.recordEvent(eventType, eventData ?? {});
  } else {
    sendLogLocal(eventType, eventData);
  }
};
