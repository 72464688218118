import { SignInResponseType } from "services/models/signInType";
import { UserResponseType } from "services/models/userDataTypes";
import { ApiStatus } from "../common/apiStatus";
import { PaymentPricingGroupsResponseType } from "../../../services/models/paymentPricingGroups";
import { CastDisplaysResponseType } from "services/models/castDisplays";

export type ApiManagementStateType = {
  isMaintenanceMode: boolean;
  signInApiStatus: ApiStatus<SignInResponseType>;
  fetchUserCurrentInfoStatus: ApiStatus<UserResponseType>;
  fetchPaymentPricingGroupsStatus: ApiStatus<PaymentPricingGroupsResponseType>;
  fetchCastDisplaysStatus: ApiStatus<CastDisplaysResponseType>;
};

/**
 * 初期化ステート
 */
export const initialState: ApiManagementStateType = {
  isMaintenanceMode: false,
  signInApiStatus: {
    status: "WAITING",
    data: null,
    error: null,
  },
  fetchUserCurrentInfoStatus: {
    status: "WAITING",
    data: null,
    error: null,
  },
  fetchPaymentPricingGroupsStatus: {
    status: "WAITING",
    data: null,
    error: null,
  },
  fetchCastDisplaysStatus: {
    status: "WAITING",
    data: null,
    error: null,
  },
};

/**
 * リセットステート
 */
export const resetState: ApiManagementStateType = {
  isMaintenanceMode: false,
  signInApiStatus: {
    status: "WAITING",
    data: null,
    error: null,
  },
  fetchUserCurrentInfoStatus: {
    status: "WAITING",
    data: null,
    error: null,
  },
  fetchPaymentPricingGroupsStatus: {
    status: "WAITING",
    data: null,
    error: null,
  },
  fetchCastDisplaysStatus: {
    status: "WAITING",
    data: null,
    error: null,
  },
};
