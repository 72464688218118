import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { apiManagementSelectors } from "../apiManagement";
import { authAsyncThunks, authSelectors } from "plugins/reduxToolkit/auth";

export type Props = {
  children: React.ReactNode;
};

export const InitializeWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const initializeApiStatus = useAppSelector(
    apiManagementSelectors.signInApiStatusSelector
  );
  const authToken = useAppSelector(authSelectors.authTokenSelector);

  useEffect(() => {
    if (initializeApiStatus.status === "WAITING" && authToken) {
      dispatch(authAsyncThunks.signInAsyncThunk(authToken));
    }
  }, [dispatch, authToken, initializeApiStatus]);

  return <>{children}</>;
};
