import { SpinnerProps, Spinner } from "@chakra-ui/react";
import React from "react";

export type Props = {
  spinnerProps?: SpinnerProps;
};
/**
 * ベーススピナー
 * @param spinnerProps SpinnerProps
 * @returns ベーススピナー
 */
export const BaseSpinner: React.FC<Props> = ({ spinnerProps }) => {
  return (
    <Spinner
      thickness="8px"
      speed="0.6s"
      emptyColor="custom.gray.xxLight"
      color="custom.primary.main"
      size="xl"
      {...spinnerProps}
    />
  );
};
