import { motion } from "framer-motion";

export type Props = {
  children: React.ReactNode;
  duration?: number;
};

/**
 * FadeInアニメーション
 * @param duration 0->1までの時間
 * @returns FadeInアニメーション
 */
export const FadeInMotionDiv: React.FC<Props> = ({
  children,
  duration = 1,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.5,
        ease: "easeOut",
        duration: duration,
      }}
    >
      {children}
    </motion.div>
  );
};
