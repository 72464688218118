import { Box } from "components/uiParts/box/Box";
import { Header } from "components/unitParts/header/Header";
import { ImageAndTextCenter } from "components/unitParts/imageAndTextSimpleParts/ImageAndTextCenter";
import React from "react";
import { SENTENCE } from "../../constants/sentence";

/**
 * 見つからないページのテンプレート
 * @returns 見つからないページのテンプレート
 */
export const NotFoundTemplate: React.FC = () => {
  return (
    <Box>
      <Header />
      <ImageAndTextCenter
        localImageSrcKey="NOT_FOUND_BLACK_600"
        text={SENTENCE.NOT_FOUND.MESSAGE}
      />
    </Box>
  );
};
