import { Menu } from "components/uiParts/menus/Menu";
import { MenuButton } from "components/uiParts/menus/MenuButton";
import { MenuItem } from "components/uiParts/menus/MenuItem";
import { MenuList } from "components/uiParts/menus/MenuList";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { WORD } from "constants/word";
import { useNavigate } from "@tanstack/react-location";

/**
 * Storeメニューを表示する
 * @returns Storeメニュー
 */
export const StoreMenu = () => {
  const navigate = useNavigate();
  return (
    <Menu>
      <MenuButton>
        {`${WORD.STORE} `}
        <ChevronDownIcon />
      </MenuButton>
      <MenuList>
        <MenuItem menuItemProps={{ onClick: () => navigate({ to: "/store" }) }}>
          {WORD.TO_FIRST_POINT_STORE}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
