import { FormLabel, FormLabelProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  formLabelProps: FormLabelProps;
  children: ReactNode;
};
/**
 * ベースフォームラベル
 * @param formLabelProps FromLabelProps
 * @param children ラベルに設定したい文字またはアイコン
 * @returns ベースフォームラベル
 */

export const BaseFormLabel: React.FC<Props> = ({
  formLabelProps,
  children,
}) => {
  return (
    <FormLabel
      _selection={{ background: "custom.selection" }}
      textColor={"custom.text.main"}
      {...formLabelProps}
    >
      {children}
    </FormLabel>
  );
};
