import { MenuListProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseMenuList } from "./BaseMenuList";

export type Props = {
  menuListProps?: MenuListProps;
  children: ReactNode;
};

/**
 * メニューリスト
 * @param menuListProps MenuListProps
 * @param children  ReactNode
 * @returns メニューリスト
 */
export const MenuList: React.FC<Props> = ({ menuListProps, children }) => {
  return <BaseMenuList menuListProps={menuListProps}>{children}</BaseMenuList>;
};
