import React from "react";
import { ColumnRowCenterFlex } from "components/uiParts/flex/ColumnRowCenterFlex";
import {
  LocalImageSrcKeyType,
  LocalImg,
} from "components/uiParts/img/LocalImg";
import { HEADER_HEIGHT } from "../header/Header";
import { Text } from "components/uiParts/text/Text";
import { FlexProps, ImageProps, TextProps } from "@chakra-ui/react";

export type Props = {
  localImageSrcKey: LocalImageSrcKeyType;
  text: string | string[];
  flexProps?: FlexProps;
  imageProps?: ImageProps;
  textProps?: TextProps;
};

/**
 * 画像とテキストを並べるユニットパーツ
 * @param localImageSrcKey 表示するローカル画像
 * @param text テキスト
 * @returns 画像テキストパーツ
 */
export const ImageAndTextCenter: React.FC<Props> = ({
  localImageSrcKey,
  text,
  flexProps,
  imageProps,
  textProps,
}) => {
  const multilineText = typeof text !== "string";

  return (
    <ColumnRowCenterFlex
      flexProps={{ height: `calc(100svh - ${HEADER_HEIGHT})`, ...flexProps }}
    >
      <LocalImg
        imageProps={{
          maxWidth: "90%",
          height: {
            base: "64px",
            sm: "100px",
          },
          ...imageProps,
        }}
        localImageSrcKey={localImageSrcKey}
      />
      <Text
        textProps={{
          textAlign: "center",
          fontSize: {
            base: "13px",
            sm: "16px",
          },
          color: "custom.text.light",
          ...textProps,
        }}
      >
        {multilineText
          ? text.map((message, index) => {
              return (
                <React.Fragment key={`${index}${message}`}>
                  {message}
                  <br />
                </React.Fragment>
              );
            })
          : text}
      </Text>
    </ColumnRowCenterFlex>
  );
};
