import React from "react";
import { Header } from "components/unitParts/header/Header";
import { CastGridDisplays } from "components/unitParts/castDisplays/CastGridDisplays";
import { Box } from "components/uiParts/box/Box";
import { BREAK_POINT } from "../../plugins/chakraUi/themes/breakpoints";

/**
 * Topテンプレート
//  * @param castDisplays 表示キャスト一覧
 * @returns Topテンプレート
 */
export const TopTemplate: React.FC = () => {
  return (
    <Box>
      <Header />
      <Box
        boxProps={{
          width: "100%",
          maxWidth: BREAK_POINT.MD,
          margin: "0 auto",
        }}
      >
        <CastGridDisplays />
      </Box>
    </Box>
  );
};
