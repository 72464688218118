import { NotFoundPage } from "components/pages/NotFoundPage";
import React from "react";

export type Props = {
  children: React.ReactNode;
};

/**
 * ローカル環境のみ閲覧できる権限を不要
 * @param children children
 * @returns Wrapper
 */
export const LocalEnvPermissionWrapper: React.FC<Props> = ({ children }) => {
  const env = process.env.REACT_APP_ENV;
  if (env === "LOCAL") {
    return <>{children}</>;
  } else {
    return <NotFoundPage />;
  }
};
