import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { resetState, StripeManagementStateType } from "./states";
import { PaymentPricingGroupsResponseType } from "../../../services/models/paymentPricingGroups";
import { convertPaymentPricingGroupsResponseToState } from "./converters";

export const ACTION_TYPE = {
  SET_PRICING_GROUPS_STATE_ACTION: "SET_PRICING_GROUPS_STATE_ACTION",
  RESET_PRICING_GROUPS_STATE_ACTION: "RESET_PRICING_GROUPS_STATE_ACTION",
};

/**
 * stripeManagementReducerを定義
 */
export const reducers = {
  // 価格グループのセット
  setPricingGroupsStateAction: (
    state: WritableDraft<StripeManagementStateType>,
    action: PayloadAction<PaymentPricingGroupsResponseType>
  ) => {
    state.pricingGroups = convertPaymentPricingGroupsResponseToState(
      action.payload
    );
  },

  // 価格グループリセット
  resetPricingGroupsStateAction: (
    state: WritableDraft<StripeManagementStateType>
  ) => {
    state.pricingGroups = resetState.pricingGroups;
  },
};
