import { ColumnCenterFlex } from "components/uiParts/flex/ColumnCenterFlex";
import { Header } from "components/unitParts/header/Header";
import { SignInIconAndText } from "components/unitParts/localImageAndText/LocalImageAndText";
import { SignInForm } from "components/unitParts/signInForm/SignInForm";
import { WORD } from "constants/word";
import { BREAK_POINT } from "plugins/chakraUi/themes/breakpoints";
import React from "react";
import { HEADER_HEIGHT } from "components/unitParts/header/Header";

export const SignInTemplate: React.FC = () => {
  return (
    <>
      <Header hideSignInButton={true} />
      <ColumnCenterFlex
        flexProps={{
          width: "100%",
          height: `calc(100svh - ${HEADER_HEIGHT})`,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SignInIconAndText
          localImageSrcKey="GREEN_LOGO_SIZE_128"
          text={WORD.SIGN_IN_UPPER_CAMEL}
        />
        <ColumnCenterFlex
          flexProps={{
            paddingX: { base: "16px", sm: "32px", md: "32px" },
            paddingY: { base: "16px", sm: "32px", md: "32px" },
            width: "100%",
            maxWidth: BREAK_POINT.MD,
            boxShadow: { base: "none", sm: "lg", md: "lg" },
          }}
        >
          <SignInForm />
        </ColumnCenterFlex>
      </ColumnCenterFlex>
    </>
  );
};
