import { FormErrorMessageProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseFormErrorMessage } from "./BaseFormErrorMessage";

export type Props = {
  formErrorMessageProps?: FormErrorMessageProps;
  children: ReactNode;
};
/**
 * フォーム用のエラーメッセージ
 * @param formErrorMessageProps FromErrorMessageProps
 * @param children エラーメッセージ
 * @returns エラーメッセージ
 */
export const FormErrorMessage: React.FC<Props> = ({
  formErrorMessageProps,
  children,
}) => {
  return (
    <BaseFormErrorMessage formErrorMessageProps={{ ...formErrorMessageProps }}>
      {children}
    </BaseFormErrorMessage>
  );
};
