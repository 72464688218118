export type SignedInModeType = "SIGN_IN" | "RE_SIGN_IN" | null;

export type AuthStateType = {
  signedIn: boolean;
  redirect: string;
  signedInMode: SignedInModeType;
  // トークン保持中以外はnull
  authToken: string | null;
};

/**
 * 初期化ステート
 */
export const initialState: AuthStateType = {
  signedIn: false,
  redirect: "/",
  signedInMode: null,
  authToken: null,
};

/**
 * リセットステート
 */
export const resetState: AuthStateType = {
  signedIn: false,
  redirect: "/",
  signedInMode: null,
  authToken: null,
};
