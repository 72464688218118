import { apiManagementSelectors } from "plugins/reduxToolkit/apiManagement";
import { authSelectors } from "plugins/reduxToolkit/auth";
import { useAppSelector } from "plugins/reduxToolkit/hooks";
import { stripeManagementSelectors } from "plugins/reduxToolkit/stripeManagement";
import { userSelectors } from "plugins/reduxToolkit/user";
import React from "react";
import { PageLoading } from "../pageLoading/PageLoading";
import { StripePricingTable } from "../pricingTable/StripePricingTable";

/**
 * 価格グループリスト情報から全てのテーブルを表示する
 * @returns 価格テーブルリスト
 */
export const StripePricingGroups: React.FC = () => {
  const fetchPaymentPricingGroupsApiStatus = useAppSelector(
    apiManagementSelectors.fetchPaymentPricingGroupsApiStatusSelector
  );
  const userAccount = useAppSelector(userSelectors.userAccountSelector);
  const authToken = useAppSelector(authSelectors.authTokenSelector);
  const pricingGroups = useAppSelector(stripeManagementSelectors.pricingGroups);

  if (fetchPaymentPricingGroupsApiStatus.status === "SUCCESS" && authToken) {
    return (
      <>
        {pricingGroups.map((group) => {
          return (
            <React.Fragment key={group.groupId}>
              {group.tableList.map((table) => {
                return (
                  <React.Fragment
                    key={`${group.groupId}${table.pricingTableId}`}
                  >
                    <StripePricingTable
                      pricingTableId={table.pricingTableId}
                      customerEmail={userAccount ? userAccount.email : ""}
                      clientReferenceId={authToken}
                    />
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </>
    );
  } else {
    return <PageLoading />;
  }
};
