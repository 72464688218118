import { FormErrorMessage, FormErrorMessageProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  formErrorMessageProps: FormErrorMessageProps;
  children: ReactNode;
};
/**
 * FormControlで出力するエラーメッセージ
 * @param formErrorMessageProps FromErrorMessageProps
 * @param children エラーメッセージ
 * @returns エラーメッセージ
 */

export const BaseFormErrorMessage: React.FC<Props> = ({
  formErrorMessageProps,
  children,
}) => {
  return (
    <FormErrorMessage
      color={"custom.danger.main"}
      _selection={{ background: "custom.selection" }}
      fontSize={"small"}
      {...formErrorMessageProps}
    >
      {children}
    </FormErrorMessage>
  );
};
