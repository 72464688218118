import { EventTypeType } from "log/eventType";
import { EventDataType } from "log/eventData";
import { TM } from "constants/tradeMark";

/**
 * ローカル環境の場合はコンソール出力する
 */
export const sendLogLocal = async (
  eventType: EventTypeType,
  eventData?: EventDataType
) => {
  console.log(
    `${
      TM.LOCAL_CUSTOM_EVENT_LOG
    }\neventType: ${eventType}\neventData: ${JSON.stringify(eventData)}`
  );
};
