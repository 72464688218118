import React, { ReactNode } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";

export type Props = {
  flexProps?: FlexProps;
  children: ReactNode;
};

/**
 * ベースフレックス
 * @param flexProps FlexProps
 * @param children unitParts
 * @returns flex
 */

export const BaseFlex: React.FC<Props> = ({ flexProps, children }) => {
  return (
    <Flex backgroundColor={"custom.background"} {...flexProps}>
      {children}
    </Flex>
  );
};
