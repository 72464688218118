export type StripeManagementPricingTableStateType = {
  pricingTableId: string;
};

export type StripeManagementPricingGroupStateType = {
  groupId: string;
  groupName: string;
  groupDescription: string | null;
  tableList: StripeManagementPricingTableStateType[];
};

export type StripeManagementPaymentPricingGroupsType =
  StripeManagementPricingGroupStateType[];

export type StripeManagementStateType = {
  pricingGroups: StripeManagementPaymentPricingGroupsType;
};

/**
 * 初期化ステート
 */
export const initialState: StripeManagementStateType = {
  pricingGroups: [],
};

/**
 * リセットステート
 */
export const resetState: StripeManagementStateType = {
  pricingGroups: [],
};
