import { PointStoreTemplate } from "components/templates/PointStoreTemplate";
import { apiManagementSelectors } from "plugins/reduxToolkit/apiManagement";
import { useAppDispatch, useAppSelector } from "plugins/reduxToolkit/hooks";
import { stripeManagementThunks } from "plugins/reduxToolkit/stripeManagement";
import React, { useEffect } from "react";

/**
 * ポイントストアページ
 * @returns PointStore
 */
export const PointStorePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const paymentGroupsApiStatus = useAppSelector(
    apiManagementSelectors.fetchPaymentPricingGroupsApiStatusSelector
  );

  useEffect(() => {
    if (paymentGroupsApiStatus.status === "WAITING") {
      dispatch(stripeManagementThunks.fetchPaymentPricingGroupsAsyncThunk());
    }
  }, [dispatch, paymentGroupsApiStatus]);

  return <PointStoreTemplate />;
};
