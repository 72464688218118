import { PaymentPricingGroupsResponseType } from "services/models/paymentPricingGroups";
import {
  StripeManagementPaymentPricingGroupsType,
  StripeManagementPricingGroupStateType,
  StripeManagementPricingTableStateType,
} from "./states";

export const convertPaymentPricingGroupsResponseToState = (
  response: PaymentPricingGroupsResponseType
): StripeManagementPaymentPricingGroupsType => {
  const result: StripeManagementPaymentPricingGroupsType =
    response.pricing_groups.map(
      (resGroup): StripeManagementPricingGroupStateType => {
        return {
          groupId: resGroup.group_id,
          groupName: resGroup.group_name,
          groupDescription: resGroup.group_description,
          tableList: resGroup.table_list.map(
            (resTable): StripeManagementPricingTableStateType => {
              return {
                pricingTableId: resTable.pricing_table_id,
              };
            }
          ),
        };
      }
    );
  return result;
};
