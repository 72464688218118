import { Box } from "components/uiParts/box/Box";
import { FixedRightTopItem } from "components/unitParts/fixedRightTopItem/FixedRightTopLink";
import { Header, HEADER_HEIGHT } from "components/unitParts/header/Header";
import { StripePricingGroups } from "components/unitParts/pricingGroups/StripePricingGroups";
import { LINK } from "constants/link";
import React from "react";
import { getItemBottomPosition } from "../unitParts/fixedRightTopItem/FixedRightTopLink";

/**
 * 価格グループからテーブルをレンダリングする
 * @returns ポイントストアテンプレート
 */
export const PointStoreTemplate: React.FC = () => {
  return (
    <Box
      boxProps={{
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        backgroundColor: "custom.primary.main",
      }}
    >
      <Header />
      <FixedRightTopItem
        title={[
          "「特定商取引法に基づく表示」及び",
          "「資金決済法に関する法律に基づく表示」",
        ]}
        index={1}
        linkProps={{
          isExternal: true,
          href: LINK.SPECIFIED_COMMERCIAL_TRANSACTION_LAW_AND_FUND_SETTLEMENT_ACT,
        }}
      />
      <Box
        boxProps={{
          paddingTop: getItemBottomPosition(2),
        }}
      />
      <StripePricingGroups />
    </Box>
  );
};
