import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

export type Props = {
  buttonProps: ButtonProps;
  title: string;
};

/**
 * ベースボタン
 * @param {buttonProps} ButtonProps
 * @param {title} ボタンタイトル
 * @returns ベースボタン
 */

export const BaseButton: React.FC<Props> = ({ buttonProps, title }) => {
  return (
    <Button _selection={{ background: "custom.selection" }} {...buttonProps}>
      {title}
    </Button>
  );
};
