import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "./type";
import { WORD } from "../../constants/word";
import { SENTENCE } from "../../constants/sentence";

/**
 * エラーレスポンス判定
 * @param response requestメソッドレスポンス
 * @returns 判定結果
 */
export const isFailedResponse = <T>(
  response: AxiosResponse<T> | ErrorResponse
): response is ErrorResponse =>
  "statusCode" in response && "message" in response && "detail" in response;

/**
 * 認証トークンが存在しない時
 */
export const notExistTokenError: ErrorResponse = {
  statusCode: "444",
  message: "認証情報が存在しません",
  detail: "認証情報が存在しないため再認証してください",
};

/**
 * AxiosResponseのエラーから状態をセット
 */
export const handleAxiosError = (
  error: AxiosError<ErrorResponse>
): ErrorResponse => {
  if (
    error.code === WORD.ERR_NETWORK_RESPONSE_CODE &&
    error.message === WORD.ERR_NETWORK_RESPONSE_MESSAGE
  ) {
    // メンテナンス_503判定
    return {
      statusCode: SENTENCE.API_ERROR.ERR_NETWORK_STATUS_CODE,
      message: SENTENCE.API_ERROR.ERR_NETWORK_MESSAGE,
      detail: SENTENCE.API_ERROR.ERR_NETWORK_DETAIL,
    };
  } else {
    // デフォルト状態
    return {
      statusCode: SENTENCE.DEFAULT.API_ERROR.STATUS_CODE,
      message: SENTENCE.DEFAULT.API_ERROR.MESSAGE,
      detail: SENTENCE.DEFAULT.API_ERROR.DETAIL,
    };
  }
};
