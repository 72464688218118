import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

export type Props = {
  textProps: TextProps;
  children: React.ReactNode;
};
/**
 * ベーステキスト
 * @param textProps TextProps
 * @param children 文字
 * @returns ベーステキスト
 */
export const BaseText: React.FC<Props> = ({ textProps, children }) => {
  return (
    <Text
      _selection={{ background: "custom.selection" }}
      color="custom.text.main"
      {...textProps}
    >
      {children}
    </Text>
  );
};
