import { ImageProps } from "@chakra-ui/react";
import React from "react";
import { BaseImg } from "./BaseImg";

export type Props = {
  imageProps: ImageProps;
};
/**
 * 画像用コンポーネント
 * @param imageProps ImageProps
 * @returns 画像
 */
export const Img: React.FC<Props> = ({ imageProps }) => {
  return <BaseImg imageProps={{ ...imageProps }}></BaseImg>;
};
