import { Menu } from "components/uiParts/menus/Menu";
import { MenuButton } from "components/uiParts/menus/MenuButton";
import { MenuItem } from "components/uiParts/menus/MenuItem";
import { MenuList } from "components/uiParts/menus/MenuList";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { WORD } from "constants/word";
import { useCallback } from "react";
import { persistor } from "plugins/reduxToolkit/persist/persistor";
import { MenuGroup } from "components/uiParts/menus/MenuGroup";
import { useAppSelector } from "../../../plugins/reduxToolkit/hooks";
import { userSelectors } from "plugins/reduxToolkit/user";
import { MenuDivider } from "components/uiParts/menus/MenuDivider";
import { LocalImg } from "components/uiParts/img/LocalImg";
import { Avatar } from "components/uiParts/avatar/Avatar";

/**
 * Accountメニューを表示する
 * @returns Accountメニュー
 */
export const AccountMenu = () => {
  const userPoint = useAppSelector(userSelectors.userPointFormatPtSelector);
  const userProfile = useAppSelector(userSelectors.userProfileSelector);
  const signOut = useCallback(async () => {
    persistor
      .purge()
      .then(() => window.location.reload())
      .catch(() => alert("error"));
  }, []);

  return (
    <Menu>
      <MenuButton>
        {`${WORD.ACCOUNT} `}
        <ChevronDownIcon />
      </MenuButton>
      <MenuList>
        {/* ユーザ情報 */}
        <MenuGroup menuGroupProps={{ title: WORD.USER_INFO }}>
          <MenuItem>
            <Avatar
              avatarProps={{
                width: "32px",
                height: "32px",
                src: userProfile?.profileImage,
                marginRight: "8px",
              }}
            />
            {userProfile?.username}
          </MenuItem>
        </MenuGroup>
        {/* 所持ポイント */}
        <MenuGroup menuGroupProps={{ title: WORD.AVAILABLE_POINT }}>
          <MenuItem>
            <LocalImg
              localImageSrcKey="FIRST_POINT_200"
              imageProps={{ width: "32px", marginRight: "8px" }}
            />
            {userPoint.firstPoint}
          </MenuItem>
          <MenuItem>
            <LocalImg
              localImageSrcKey="SECOND_POINT_200"
              imageProps={{ width: "32px", marginRight: "8px" }}
            />
            {userPoint.secondPoint}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        {/* サインアウト */}
        <MenuItem
          menuItemProps={{
            onClick: signOut,
            color: "custom.danger.main",
          }}
        >
          {WORD.SIGN_OUT}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
