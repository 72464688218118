import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { UserResponseType } from "services/models/userDataTypes";
import { convertUserResponseToState } from "./converters";
import { UserStateType, resetState } from "./states";

export const ACTION_TYPE = {
  SET_USER_PENDING_STATE_ACTION: "SET_USER_PENDING_STATE_ACTION",
  SET_USER_SUCCESS_STATE_ACTION: "SET_USER_SUCCESS_STATE_ACTION",
  SET_USER_FAILED_STATE_ACTION: "SET_USER_FAILED_STATE_ACTION",
  RESET_STATE_ACTION: "RESET_STATE_ACTION",
};

/**
 * userReducersを定義
 */
export const reducers = {
  // ユーザ情報取得成功時
  setUserSuccessStateAction: (
    state: WritableDraft<UserStateType>,
    action: PayloadAction<UserResponseType>
  ) => {
    const convertedPayload = convertUserResponseToState(action.payload);
    state.account = convertedPayload.account;
    state.profile = convertedPayload.profile;
    state.point = convertedPayload.point;
    state.setting = convertedPayload.setting;
    state.bank = convertedPayload.bank;
  },

  // ステートをリセットする
  resetStateAction: (state: WritableDraft<UserStateType>) => {
    state.account = resetState.account;
    state.profile = resetState.profile;
    state.point = resetState.point;
    state.setting = resetState.setting;
    state.bank = resetState.bank;
  },
};
