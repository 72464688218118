import greenLogoSize64 from "assets/greenLogo/hw64.png";
import greenLogoSize128 from "assets/greenLogo/hw128.png";
import logoAndIconSize200 from "assets/logoAndIcons/w200.png";
import logoAndIconSize300 from "assets/logoAndIcons/w300.png";
import activeCast600 from "assets/activeCastText/w600.png";
import firstPoint200 from "assets/firstPoint/w200.png";
import secondPoint200 from "assets/secondPoint/w200.png";
import notFoundBlack600 from "assets/404black/w600.png";
import maintenanceBlack600 from "assets/maintenanceBlack/w600.png";

export const LOCAL_IMAGE = {
  GREEN_LOGO_SIZE_128: greenLogoSize128,
  LOGO_AND_ICON_SIZE_200: logoAndIconSize200,
  LOGO_AND_ICON_SIZE_300: logoAndIconSize300,
  USER_ICON_SIZE_64: greenLogoSize64,
  ACTIVE_CAST_TEXT_600: activeCast600,
  FIRST_POINT_200: firstPoint200,
  SECOND_POINT_200: secondPoint200,
  NOT_FOUND_BLACK_600: notFoundBlack600,
  MAINTENANCE_BLACK_600: maintenanceBlack600,
};
