import { ColorStage } from "./types";

export const LIGHT_THEME_WARNING: ColorStage = {
  xxLight: "#FFC35C",
  xLight: "#FFB433",
  light: "#FFA50A",
  main: "#E08E00",
  dark: "#CC8100",
  xDark: "#A36700",
  xxDark: "#7A4E00",
  contrastText: "#FFFFFF",
};

export const DARK_THEME_WARNING: ColorStage = {
  xxLight: "#FFC35C",
  xLight: "#FFB433",
  light: "#FFA50A",
  main: "#E08E00",
  dark: "#CC8100",
  xDark: "#A36700",
  xxDark: "#7A4E00",
  contrastText: "#FFFFFF",
};
