import { createSelector } from "reselect";
import { RootState } from "../store";
import {
  StripeManagementStateType,
  StripeManagementPaymentPricingGroupsType,
} from "./states";

/**
 * stripeManagementを全て取得する
 * @param state state
 * @returns stripeManagement
 */
export const authSelector = (state: RootState): StripeManagementStateType =>
  state.stripeManagement;

/**
 * StripeManagementグループを取得
 */
export const pricingGroups = createSelector(
  authSelector,
  (stripeManagement): StripeManagementPaymentPricingGroupsType => {
    return stripeManagement.pricingGroups;
  }
);
