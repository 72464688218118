import { Menu, MenuProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  menuProps?: MenuProps;
  children: ReactNode;
};

/**
 * メニュー
 * @param menuProps MenuProps
 * @param children  ReactNode
 * @returns メニュー
 */
export const BaseMenu: React.FC<Props> = ({ menuProps, children }) => {
  return <Menu {...menuProps}>{children}</Menu>;
};
