import { MenuGroupProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseMenuGroup } from "./BaseMenuGroup";

export type Props = {
  menuGroupProps?: MenuGroupProps;
  children: ReactNode;
};

/**
 * メニューグループ
 * @param menuGroupProps MenuGroupProps
 * @param children  ReactNode
 * @returns メニューグループ
 */
export const MenuGroup: React.FC<Props> = ({ menuGroupProps, children }) => {
  return (
    <BaseMenuGroup menuGroupProps={menuGroupProps}>{children}</BaseMenuGroup>
  );
};
