import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./reducers";
import { initialState } from "./states";
import { extraReducers } from "./extraReducers";

/**
 * apiManagementSliceを定義
 */
export const apiManagementSlice = createSlice({
  name: "apiManagement",
  initialState,
  reducers,
  extraReducers,
});
