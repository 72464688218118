import { ColumnRowCenterFlex } from "components/uiParts/flex/ColumnRowCenterFlex";
import { Spinner } from "components/uiParts/spinner/Spinner";
import React from "react";
import { HEADER_HEIGHT } from "../header/Header";

/**
 * ページ全体のローディングスピナーを表示する
 * 100VHのため画面全体のローディングに使用すること
 * @returns スピナー
 */
export const PageLoading: React.FC = () => {
  return (
    <ColumnRowCenterFlex
      flexProps={{
        height: `calc(100svh - ${HEADER_HEIGHT})`,
      }}
    >
      <Spinner />
    </ColumnRowCenterFlex>
  );
};
