import { NotFoundTemplate } from "components/templates/NotFoundTemplate";
import React from "react";

/**
 * 見つからないページ
 * @returns page
 */
export const NotFoundPage: React.FC = () => {
  return <NotFoundTemplate/>
};
