import React from "react";

export type Props = {
  pricingTableId: string;
  customerEmail: string;
  clientReferenceId: string;
  publishableKey?: string;
};

/**
 * Stripe購入テーブル表示コンポーネント
 * @param pricingTableId 価格テーブルID
 * @param publishableKey 公開キー
 * @returns Stripe購入テーブル表示コンポーネント
 */
export const StripePricingTable: React.FC<Props> = ({
  pricingTableId,
  publishableKey,
  customerEmail,
  clientReferenceId,
}) => {
  const key: string =
    publishableKey ?? process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "";
  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={key}
      customer-email={customerEmail}
      client-reference-id={clientReferenceId}
    ></stripe-pricing-table>
  );
};
