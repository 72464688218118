import { MenuItem, MenuItemProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { clickableStyle } from "../styles/hover";

export type Props = {
  menuItemProps?: MenuItemProps;
  children: ReactNode;
};

/**
 * メニューアイテム
 * @param menuItemProps MenuItemProps
 * @param children  ReactNode
 * @returns メニューアイテム
 */
export const BaseMenuItem: React.FC<Props> = ({ menuItemProps, children }) => {
  return (
    <MenuItem
      _selection={{ background: "custom.selection" }}
      fontSize={"14px"}
      backgroundColor={"custom.background"}
      color={"custom.text.main"}
      _hover={clickableStyle}
      {...menuItemProps}
    >
      {children}
    </MenuItem>
  );
};
