import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { signInAsyncThunk } from "./asyncThunks";
import { ACTION_TYPE } from "./reducers";
import { authSlice } from "./slice";
import { AuthStateType } from "./states";

/**
 * ExtraReducersを定義
 */
export const extraReducers = (
  builder: ActionReducerMapBuilder<AuthStateType>
) => {
  builder.addCase(signInAsyncThunk.fulfilled, (state, action) => {
    authSlice.caseReducers.setSignInSuccessStateAction(state, {
      type: ACTION_TYPE.SET_SIGN_IN_SUCCESS_STATE_ACTION,
      payload: action.payload,
    });
  });
};
