import { LinkProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseChakraLink } from "./BaseChakraLink";

export type Props = {
  linkProps?: LinkProps;
  children: ReactNode;
};

/**
 * リンク
 * @param linkProps LinkProps
 * @param children  ReactNode
 * @returns リンク
 */
export const ChakraLink: React.FC<Props> = ({ linkProps, children }) => {
  return <BaseChakraLink linkProps={linkProps}>{children}</BaseChakraLink>;
};
