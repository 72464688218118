import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { ErrorResponse } from "plugins/axios";
import { SignInResponseType } from "services/models/signInType";
import { ApiManagementStateType, resetState } from "./states";
import { UserResponseType } from "../../../services/models/userDataTypes";
import { PaymentPricingGroupsResponseType } from "services/models/paymentPricingGroups";
import { CastDisplaysResponseType } from "services/models/castDisplays";

export const ACTION_TYPE = {
  SET_SIGN_IN_API_PENDING_STATE_ACTION: "SET_SIGN_IN_API_PENDING_STATE_ACTION",
  SET_SIGN_IN_API_SUCCESS_STATE_ACTION: "SET_SIGN_IN_API_SUCCESS_STATE_ACTION",
  SET_SIGN_IN_API_FAILED_STATE_ACTION: "SET_SIGN_IN_API_FAILED_STATE_ACTION",
  RESET_STATE_ACTION: "RESET_STATE_ACTION",
  SET_FETCH_USER_CURRENT_INFO_API_PENDING_STATE_ACTION:
    "SET_FETCH_USER_CURRENT_INFO_API_PENDING_STATE_ACTION",
  SET_FETCH_USER_CURRENT_INFO_API_SUCCESS_STATE_ACTION:
    "SET_FETCH_USER_CURRENT_INFO_API_SUCCESS_STATE_ACTION",
  SET_FETCH_USER_CURRENT_INFO_API_FAILED_STATE_ACTION:
    "SET_FETCH_USER_CURRENT_INFO_API_FAILED_STATE_ACTION",
  SET_FETCH_PAYMENT_PRICING_GROUPS_API_PENDING_STATE_ACTION:
    "SET_FETCH_PAYMENT_PRICING_GROUPS_API_PENDING_STATE_ACTION",
  SET_FETCH_PAYMENT_PRICING_GROUPS_API_SUCCESS_STATE_ACTION:
    "SET_FETCH_PAYMENT_PRICING_GROUPS_API_SUCCESS_STATE_ACTION",
  SET_FETCH_PAYMENT_PRICING_GROUPS_API_FAILED_STATE_ACTION:
    "SET_FETCH_PAYMENT_PRICING_GROUPS_API_FAILED_STATE_ACTION",
  SET_FETCH_CAST_DISPLAYS_API_PENDING_STATE_ACTION:
    "SET_FETCH_CAST_DISPLAYS_API_PENDING_STATE_ACTION",
  SET_FETCH_CAST_DISPLAYS_API_SUCCESS_STATE_ACTION:
    "SET_FETCH_CAST_DISPLAYS_API_SUCCESS_STATE_ACTION",
  SET_FETCH_CAST_DISPLAYS_API_FAILED_STATE_ACTION:
    "SET_FETCH_CAST_DISPLAYS_API_FAILED_STATE_ACTION",
};

/**
 * apiManagementReducersを定義
 */
export const reducers = {
  // サインイン処理中
  setSignInApiPendingStateAction: (
    state: WritableDraft<ApiManagementStateType>
  ) => {
    state.signInApiStatus.status = "PENDING";
    state.signInApiStatus.data = null;
    state.signInApiStatus.error = null;
  },

  // サインイン成功時
  setSignInApiSuccessStateAction: (
    state: WritableDraft<ApiManagementStateType>,
    action: PayloadAction<SignInResponseType>
  ) => {
    state.signInApiStatus.status = "SUCCESS";
    state.signInApiStatus.data = action.payload;
    state.signInApiStatus.error = null;
  },

  // サインイン失敗時
  setSignInApiFailedStateAction: (
    state: WritableDraft<ApiManagementStateType>,
    action: PayloadAction<ErrorResponse | undefined>
  ) => {
    state.signInApiStatus.status = "FAILED";
    state.signInApiStatus.data = null;
    state.signInApiStatus.error = action?.payload ?? null;
  },

  // ユーザー情報取得処理中
  setFetchUserCurrentInfoApiPendingStateAction: (
    state: WritableDraft<ApiManagementStateType>
  ) => {
    state.fetchUserCurrentInfoStatus.status = "PENDING";
    state.fetchUserCurrentInfoStatus.error = null;
  },

  // ユーザー情報取得成功時
  setFetchUserCurrentInfoApiSuccessStateAction: (
    state: WritableDraft<ApiManagementStateType>,
    action: PayloadAction<UserResponseType>
  ) => {
    state.fetchUserCurrentInfoStatus.status = "SUCCESS";
    state.fetchUserCurrentInfoStatus.data = action.payload;
    state.fetchUserCurrentInfoStatus.error = null;
  },

  // ユーザー情報取得失敗時
  setFetchUserCurrentInfoApiFailedStateAction: (
    state: WritableDraft<ApiManagementStateType>,
    action: PayloadAction<ErrorResponse | undefined>
  ) => {
    state.fetchUserCurrentInfoStatus.status = "FAILED";
    state.fetchUserCurrentInfoStatus.data = null;
    state.fetchUserCurrentInfoStatus.error = action?.payload ?? null;
  },

  // 価格テーブル取得処理中
  setFetchPaymentPricingGroupsApiPendingStateAction: (
    state: WritableDraft<ApiManagementStateType>
  ) => {
    state.fetchPaymentPricingGroupsStatus.status = "PENDING";
    state.fetchPaymentPricingGroupsStatus.error = null;
  },

  // 価格テーブル取得成功時
  setFetchPaymentPricingGroupsApiSuccessStateAction: (
    state: WritableDraft<ApiManagementStateType>,
    action: PayloadAction<PaymentPricingGroupsResponseType>
  ) => {
    state.fetchPaymentPricingGroupsStatus.status = "SUCCESS";
    state.fetchPaymentPricingGroupsStatus.data = action.payload;
    state.fetchPaymentPricingGroupsStatus.error = null;
  },

  // 価格テーブル取得失敗時
  setFetchPaymentPricingGroupsApiFailedStateAction: (
    state: WritableDraft<ApiManagementStateType>,
    action: PayloadAction<ErrorResponse | undefined>
  ) => {
    state.fetchPaymentPricingGroupsStatus.status = "FAILED";
    state.fetchPaymentPricingGroupsStatus.data = null;
    state.fetchPaymentPricingGroupsStatus.error = action?.payload ?? null;
  },

  // 表示用キャスト取得処理中
  setFetchCastDisplaysApiPendingStateAction: (
    state: WritableDraft<ApiManagementStateType>
  ) => {
    state.fetchCastDisplaysStatus.status = "PENDING";
    state.fetchCastDisplaysStatus.error = null;
  },

  // 表示用キャスト取得成功時
  setFetchCastDisplaysApiSuccessStateAction: (
    state: WritableDraft<ApiManagementStateType>,
    action: PayloadAction<CastDisplaysResponseType>
  ) => {
    state.fetchCastDisplaysStatus.status = "SUCCESS";
    state.fetchCastDisplaysStatus.data = action.payload;
    state.fetchCastDisplaysStatus.error = null;
  },

  // 表示用キャスト取得失敗時
  setFetchCastDisplaysApiFailedStateAction: (
    state: WritableDraft<ApiManagementStateType>,
    action: PayloadAction<ErrorResponse | undefined>
  ) => {
    state.fetchCastDisplaysStatus.status = "FAILED";
    state.fetchCastDisplaysStatus.data = null;
    state.fetchCastDisplaysStatus.error = action?.payload ?? null;
  },

  // メンテナンスモードON
  setMaintenanceModeOn: (state: WritableDraft<ApiManagementStateType>) => {
    state.isMaintenanceMode = true;
  },

  // 全てのステートをリセットする
  resetAllStateAction: (state: WritableDraft<ApiManagementStateType>) => {
    state.signInApiStatus = resetState.signInApiStatus;
    state.fetchUserCurrentInfoStatus = resetState.fetchUserCurrentInfoStatus;
    state.fetchPaymentPricingGroupsStatus =
      resetState.fetchPaymentPricingGroupsStatus;
    state.fetchCastDisplaysStatus = resetState.fetchCastDisplaysStatus;
  },
};
