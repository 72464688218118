import { FormControl, FormControlProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  formControlProps: FormControlProps;
  children: ReactNode;
};
/**
 * ベースフォームコントロール
 * @param formControlProps FromControlProps
 * @param children ReactNode
 * @returns ベースフォームコントロール
 */

export const BaseFormControl: React.FC<Props> = ({
  formControlProps,
  children,
}) => {
  return <FormControl {...formControlProps}>{children}</FormControl>;
};
