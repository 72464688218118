import React from "react";
import { Box } from "components/uiParts/box/Box";
import { BoxProps, LinkProps, TextProps } from "@chakra-ui/react";
import { HEADER_HEIGHT } from "../header/Header";
import { ChakraLink } from "components/uiParts/chakraLink/ChakraLink";
import { Text } from "components/uiParts/text/Text";

const BASE_MARGIN = 32;
const PITCH = 8;
const BUTTON_HEIGHT = 40;

const textLinkStyle: TextProps = {
  color: "custom.link",
  fontSize: { base: "12px", sm: "14px" },
  textDecoration: "underline",
};

/**
 * 固定アイテムの上部のPXを取得
 */
export const getItemTopPosition = (index: number): number => {
  return BASE_MARGIN + BUTTON_HEIGHT * index + (index - 1) * PITCH;
};

/**
 * 固定アイテムの下部のPXを取得
 */
export const getItemBottomPosition = (index: number): number => {
  return (
    BASE_MARGIN + BUTTON_HEIGHT * index + (index - 1) * PITCH + BUTTON_HEIGHT
  );
};

export type Props = {
  title: string | string[];
  index?: number;
  onClick?: () => void;
  boxProps?: BoxProps;
  linkProps?: LinkProps;
};

/**
 * ヘッダー下固定でボタンを配置する
 * @param title タイトル
 * @param index 配置番号
 * @param onClick クリックイベント
 * @param boxProps boxProps
 * @param linkProps linkProps
 * @returns 固定メニュー
 */
export const FixedRightTopItem: React.FC<Props> = ({
  title,
  index,
  boxProps,
  linkProps,
}) => {
  // indexは1以上の値にする
  const indexNumber = index === undefined || index < 1 ? 1 : index;
  const calcPosition = getItemTopPosition(indexNumber);
  return (
    <Box
      boxProps={{
        position: "fixed",
        top: `calc(${HEADER_HEIGHT} + ${calcPosition}px)`,
        right: "16px",
        backgroundColor: "custom.selection",
        padding: "8px",
        ...boxProps,
      }}
    >
      <ChakraLink linkProps={linkProps}>
        {/* 引数がリストの場合Mapで生成する */}
        {typeof title === "string" ? (
          <Text textProps={textLinkStyle}>{title}</Text>
        ) : (
          title.map((row, index) => (
            <Text key={index} textProps={textLinkStyle}>
              {row}
            </Text>
          ))
        )}
      </ChakraLink>
    </Box>
  );
};
