import { WORD } from "constants/word";
import { createSelector } from "reselect";
import { RootState } from "../store";
import { UserStateType } from "./states";

/**
 * userを全て取得する
 * @param state state
 * @returns user
 */
export const userSelector = (state: RootState): UserStateType => state.user;

/**
 * アカウント情報を取得する
 */
export const userAccountSelector = createSelector(userSelector, (user) => {
  return user.account;
});

/**
 * プロフィールを取得する
 */
export const userProfileSelector = createSelector(userSelector, (user) => {
  return user.profile;
});

/**
 * ポイントを数値で取得する
 */
export const userPointSelector = createSelector(userSelector, (user) => {
  return user.point ?? { firstPoint: 0, secondPoint: 0 };
});

/**
 * ポイントを文字列ptでフォーマットして取得する
 */
export const userPointFormatPtSelector = createSelector(
  userSelector,
  (
    user
  ): {
    firstPoint: string;
    secondPoint: string;
  } => {
    if (user.point) {
      return {
        firstPoint: `${user.point.firstPoint}${WORD.FIRST_POINT_NAME_PT}`,
        secondPoint: `${user.point.secondPoint}${WORD.SECOND_POINT_NAME_PT}`,
      };
    } else {
      return {
        firstPoint: "-",
        secondPoint: "-",
      };
    }
  }
);

/**
 * 設定を取得する
 */
export const userSettingSelector = createSelector(userSelector, (user) => {
  return user.setting;
});

/**
 * 支払い先口座情報を取得する
 */
export const userBankSelector = createSelector(userSelector, (user) => {
  return user.bank;
});
