import { FormLabelProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseFormLabel } from "./BaseFormLabel";

export type Props = {
  formLabelProps?: FormLabelProps;
  children: ReactNode;
};
/**
 * フォームのlabel要素
 * @param formLabelProps FormLabelProps
 * @param children ラベルに設定したい文字またはアイコン
 * @returns ラベル
 */

export const FormLabel: React.FC<Props> = ({ formLabelProps, children }) => {
  return (
    <BaseFormLabel formLabelProps={{ ...formLabelProps }}>
      {children}
    </BaseFormLabel>
  );
};
