import React from "react";
import { ChakraUiProvider } from "./plugins/chakraUi/provider";
import { ReduxToolkitProvider } from "./plugins/reduxToolkit/provider";
import { ThemeContextProvider } from "./contexts/themeContext/provider";
import { lightModeTheme } from "./plugins/chakraUi/themes";
import { ReactLocationRouter } from "./plugins/reactLocation/ReactLocationRouter";
import { PersistGate } from "plugins/reduxToolkit/persist/PersistGate";
import { ObserverToastWrapper } from "components/common/ObserverToastWrapper";
import { MaintenanceWrapper } from "components/common/MaintenanceWrapper";

/**
 * Appコンポーネント
 * @returns Appコンポーネント
 */
export const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <ChakraUiProvider theme={lightModeTheme}>
        <ReduxToolkitProvider>
          <PersistGate>
            <ObserverToastWrapper>
              <MaintenanceWrapper>
                <ReactLocationRouter />
              </MaintenanceWrapper>
            </ObserverToastWrapper>
          </PersistGate>
        </ReduxToolkitProvider>
      </ChakraUiProvider>
    </ThemeContextProvider>
  );
};
