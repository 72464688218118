import { configureStore } from "@reduxjs/toolkit";
import { apiManagementReducer } from "./apiManagement";
import { stripeManagementReducers } from "./stripeManagement";
import { userReducer } from "./user";
import { authPersistedReducer } from "./persist/reducers";
import { castReducers } from "./cast";

export const store = configureStore({
  reducer: {
    auth: authPersistedReducer,
    apiManagement: apiManagementReducer,
    user: userReducer,
    stripeManagement: stripeManagementReducers,
    cast: castReducers,
  },
  devTools: !(process.env.REACT_APP_ENV === "PRD"),
  middleware: (defaultMiddleware) =>
    defaultMiddleware({
      // serializableCheck警告を排除する
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/PURGE"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
