import { ColorStage } from "./types";

export const LIGHT_THEME_GRAY: ColorStage = {
  xxLight: "#F4F5F6",
  xLight: "#E8EAED",
  light: "#D1D6DB",
  main: "#ADB5BD",
  dark: "#99A3AD",
  xDark: "#828F9B",
  xxDark: "#6D7A88",
  contrastText: "#2E3033",
};

export const DARK_THEME_GRAY: ColorStage = {
  xxLight: "#B8B8B8",
  xLight: "#AAAAAA",
  light: "#9B9B9B",
  main: "#7D7D7D",
  dark: "#686868",
  xDark: "#5D5D5D",
  xxDark: "#525252",
  contrastText: "#EBEBEB",
};
