import { MenuProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseMenu } from "./BaseMenu";

export type Props = {
  menuProps?: MenuProps;
  children: ReactNode;
};

/**
 * メニュー
 * @param menuProps MenuProps
 * @param children  ReactNode
 * @returns メニュー
 */
export const Menu: React.FC<Props> = ({ menuProps, children }) => {
  return <BaseMenu menuProps={menuProps}>{children}</BaseMenu>;
};
