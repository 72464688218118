import { ColorStage } from "./types";

export const LIGHT_THEME_PRIMARY: ColorStage = {
  xxLight: "#33FFB4",
  xLight: "#0AFFA5",
  light: "#00F59B",
  main: "#00E08F",
  dark: "#00CC81",
  xDark: "#00B874",
  xxDark: "#00A367",
  contrastText: "#FFFFFF",
};

export const DARK_THEME_PRIMARY: ColorStage = {
  xxLight: "#33FFB4",
  xLight: "#0AFFA5",
  light: "#00F59B",
  main: "#00E08F",
  dark: "#00CC81",
  xDark: "#00B874",
  xxDark: "#00A367",
  contrastText: "#FFFFFF",
};
