import { MenuItemProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseMenuItem } from "./BaseMenuItem";

export type Props = {
  menuItemProps?: MenuItemProps;
  children: ReactNode;
};

/**
 * メニューアイテム
 * @param menuItemProps MenuItemProps
 * @param children  ReactNode
 * @returns メニューアイテム
 */
export const MenuItem: React.FC<Props> = ({ menuItemProps, children }) => {
  return <BaseMenuItem menuItemProps={menuItemProps}>{children}</BaseMenuItem>;
};
