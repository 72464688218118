import { MaintenanceTemplate } from "components/templates/MaintenanceTemplate";
import React from "react";

/**
 * メンテナンスページ
 * @returns page
 */
export const MaintenancePage: React.FC = () => {
  return <MaintenanceTemplate />;
};
