import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { SignInResponseType } from "services/models/signInType";
import { AuthStateType, resetState, SignedInModeType } from "./states";

export const ACTION_TYPE = {
  SET_SIGN_IN_PENDING_STATE_ACTION: "SET_SIGN_IN_PENDING_STATE_ACTION",
  SET_SIGN_IN_SUCCESS_STATE_ACTION: "SET_SIGN_IN_SUCCESS_STATE_ACTION",
  SET_SIGN_IN_FAILED_STATE_ACTION: "SET_SIGN_IN_FAILED_STATE_ACTION",
  RESET_STATE_ACTION: "RESET_STATE_ACTION",
};

/**
 * authReducersを定義
 */
export const reducers = {
  // サインイン成功時
  setSignInSuccessStateAction: (
    state: WritableDraft<AuthStateType>,
    action: PayloadAction<SignInResponseType>
  ) => {
    state.authToken = action.payload.auth_token;
    state.signedIn = true;
  },

  // サインイン失敗時
  setSignInFailedStateAction: (state: WritableDraft<AuthStateType>) => {
    state.signedIn = false;
    state.authToken = null;
  },

  // サインイン後の遷移先を登録する
  setRedirect: (
    state: WritableDraft<AuthStateType>,
    action: PayloadAction<string>
  ) => {
    state.redirect = action.payload;
  },

  // サインイン後の遷移先をリセットする
  resetRedirect: (state: WritableDraft<AuthStateType>) => {
    state.redirect = "/";
  },

  // サインイン方法をセットする
  setSignedInMode: (
    state: WritableDraft<AuthStateType>,
    action: PayloadAction<SignedInModeType>
  ) => {
    state.signedInMode = action.payload;
  },

  // ステートをリセットする
  resetStateAction: (state: WritableDraft<AuthStateType>) => {
    state.authToken = resetState.authToken;
    state.signedIn = resetState.signedIn;
    state.redirect = resetState.redirect;
    state.signedInMode = resetState.signedInMode;
  },
};
