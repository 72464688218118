import React from "react";

export type Props = {
  children: React.ReactNode;
};

/**
 * どんなユーザでも閲覧できる権限を付与する
 * @param children children
 * @returns Wrapper
 */
export const GuestUserPermissionWrapper: React.FC<Props> = ({ children }) => {
  return <>{children}</>;
};
