import { request } from "plugins/axios/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PaymentPricingGroupsResponseType } from "../../../services/models/paymentPricingGroups";
import { AsyncThunkConfig } from "../common/asyncThunkConfig";
import { isFailedResponse, notExistTokenError } from "plugins/axios/utils";
import { generateAuthorizationHeader } from "utils/generateAuthorizationHeader";

/**
 * 価格グループ一覧取得APIを非同期実行
 */
export const fetchPaymentPricingGroupsAsyncThunk = createAsyncThunk<
  PaymentPricingGroupsResponseType,
  undefined,
  AsyncThunkConfig
>(
  "stripeManagement/paymentPricingGroups",
  async (_, { rejectWithValue, getState }) => {
    const authToken: string | null = getState().auth.authToken;
    if (!authToken) {
      return rejectWithValue(notExistTokenError);
    }
    const response = await request<
      PaymentPricingGroupsResponseType,
      unknown,
      unknown
    >(
      "GET",
      "PAYMENT_PRICING_GROUPS",
      undefined,
      undefined,
      generateAuthorizationHeader(authToken)
    );

    if (isFailedResponse(response)) {
      return rejectWithValue(response);
    } else {
      return response.data;
    }
  }
);
