import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { CastStateType, resetState } from "./states";
import { convertCastDisplaysResponseToState } from "./converters";
import { CastDisplaysResponseType } from "services/models/castDisplays";

export const ACTION_TYPE = {
  SET_PRICING_GROUPS_STATE_ACTION: "SET_CAST_DISPLAYS_STATE_ACTION",
  RESET_PRICING_GROUPS_STATE_ACTION: "RESET_CAST_DISPLAYS_STATE_ACTION",
};

/**
 * castReducerを定義
 */
export const reducers = {
  // 価格グループのセット
  setCastDisplaysStateAction: (
    state: WritableDraft<CastStateType>,
    action: PayloadAction<CastDisplaysResponseType>
  ) => {
    state.displays = convertCastDisplaysResponseToState(action.payload);
  },

  // 価格グループリセット
  resetCastDisplaysStateAction: (state: WritableDraft<CastStateType>) => {
    state.displays = resetState.displays;
  },
};
