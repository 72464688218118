const NOT_FOUND = {
  MESSAGE_1: "ご指定のページが見つかりませんでした",
  MESSAGE_2: "アクセスしようとしたページは削除・変更、または",
  MESSAGE_3: "現在利用できない可能性があります。",
  MESSAGE_4: "お手数お掛けしますが再度アクセスいただくよう",
  MESSAGE_5: "お願いいたします。",
};

const MAINTENANCE = {
  MESSAGE_1: "メンテナンス実施中",
  MESSAGE_2: "ただいまメンテナンスを実施しております、ご利用の皆さまには",
  MESSAGE_3:
    "ご不便をおかけしますが、ご理解とご協力を何卒よろしくお願い申し上げます。",
};

const EMAIL_VALIDATION = {
  REQUIRED_ERROR: "メールアドレスを入力してください",
  INVALID_FORMAT_ERROR: "正しいメールアドレスを入力してください",
};
const PASSWORD_VALIDATION = {
  REQUIRED_ERROR: "パスワードを入力してください",
  SIZE_ERROR: "8～16文字でパスワードを入力してください",
  INVALID_FORMAT_ERROR: "正しいパスワードを入力してください",
};

const TOAST = {
  TITLE: {
    SIGN_IN_SUCCESS: "ようこそONERECOへ",
    SIGN_IN_FAILED: "サインインに失敗しました",
    MAINTENANCE_MODE: "メンテナンス実施中",
  },
  DESCRIPTION: {
    SIGN_IN_FAILED:
      "メールアドレス・パスワードが正しく入力されているかお確かめください",
    MAINTENANCE_MODE:
      "ただいまメンテナンスを実施しております。ご利用の皆さまにはご不便をおかけしますが、ご理解とご協力を何卒よろしくお願い申し上げます。",
  },
};

export const SENTENCE = {
  NOT_FOUND: {
    MESSAGE: Object.values(NOT_FOUND).map((v) => v),
  },
  MAINTENANCE: {
    MESSAGE: Object.values(MAINTENANCE).map((v) => v),
  },
  VALIDATION: {
    EMAIL: EMAIL_VALIDATION,
    PASSWORD: PASSWORD_VALIDATION,
  },
  DEFAULT: {
    API_ERROR: {
      STATUS_CODE: "999",
      MESSAGE: "通信に失敗しました",
      DETAIL: "通信に失敗しました",
    },
  },
  API_ERROR: {
    SIGN_IN_MESSAGE: "サインインに失敗しました",
    SIGN_IN_DETAIL: "サインインに失敗しました",

    ERR_NETWORK_STATUS_CODE: "503",
    ERR_NETWORK_MESSAGE: "サーバメンテナンス",
    ERR_NETWORK_DETAIL: "サーバ一時停止中",
  },
  ERROR_MESSAGE: "メッセージを検討中",
  FORGET_PASSWORD: "パスワードを忘れたとき",
  FORGET_PASSWORD_ACTION:
    "アプリ版のログイン画面よりパスワードを再設定いただけます。お手数ですがアプリ版からお試しください。",
  TOAST: TOAST,
};
