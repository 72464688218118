import React from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../plugins/reduxToolkit/hooks";
import { authSelectors } from "plugins/reduxToolkit/auth";
import { SignInPage } from "components/pages/SignInPage";
import { authActions } from "../../plugins/reduxToolkit/auth/index";

export type Props = {
  children: React.ReactNode;
  redirect: string;
};

/**
 * 認証済みユーザのみ閲覧できる権限を付与する
 * 元ページへのリンクをグローバルステートに記憶させておく
 * @param children children
 * @returns Wrapper
 */
export const AuthenticatedUserPermissionWrapper: React.FC<Props> = ({
  children,
  redirect,
}) => {
  const signedIn = useAppSelector(authSelectors.signedInSelector);
  const dispatch = useAppDispatch();

  if (signedIn) {
    return <>{children}</>;
  } else {
    dispatch(authActions.setRedirect(redirect));
    return <SignInPage />;
  }
};
