import React from "react";
import { ReactNode } from "react";
import { Provider } from "react-redux";
import { store } from "./store";

export type Props = {
  children: ReactNode;
};

/**
 * ReduxToolkitプロバイダー
 * @param children ReactNode
 */
export const ReduxToolkitProvider: React.FC<Props> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
