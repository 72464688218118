import { ImageProps } from "@chakra-ui/react";
import { LOCAL_IMAGE } from "constants/localImage";
import React from "react";
import { BaseImg } from "./BaseImg";

export type LocalImageSrcKeyType = keyof typeof LOCAL_IMAGE;

export type Props = {
  localImageSrcKey: LocalImageSrcKeyType;
  imageProps?: ImageProps;
};
/**
 * ローカル画像
 * @param imageProps ImageProps
 * @returns ローカル画像コンポーネント
 */
export const LocalImg: React.FC<Props> = ({ localImageSrcKey, imageProps }) => {
  return (
    <BaseImg
      imageProps={{ src: LOCAL_IMAGE[localImageSrcKey], ...imageProps }}
    ></BaseImg>
  );
};
