import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ACTION_TYPE } from "./reducers";
import { apiManagementSlice } from "./slice";
import { ApiManagementStateType } from "./states";
import { fetchPaymentPricingGroupsAsyncThunk } from "../stripeManagement/asyncThunks";
import { signInAsyncThunk } from "../auth/asyncThunks";
import { fetchUserCurrentInfoAsyncThunk } from "../user/asyncThunks";
import { fetchCastDisplaysAsyncThunk } from "../cast/asyncThunks";

/**
 * ExtraReducersを定義
 */
export const extraReducers = (
  builder: ActionReducerMapBuilder<ApiManagementStateType>
) => {
  builder.addCase(signInAsyncThunk.pending, (apiState) => {
    apiManagementSlice.caseReducers.setSignInApiPendingStateAction(apiState);
  });

  builder.addCase(signInAsyncThunk.fulfilled, (apiState, action) => {
    apiManagementSlice.caseReducers.setSignInApiSuccessStateAction(apiState, {
      type: ACTION_TYPE.SET_SIGN_IN_API_SUCCESS_STATE_ACTION,
      payload: action.payload,
    });
  });

  builder.addCase(signInAsyncThunk.rejected, (apiState, action) => {
    apiManagementSlice.caseReducers.setSignInApiFailedStateAction(apiState, {
      type: ACTION_TYPE.SET_SIGN_IN_API_FAILED_STATE_ACTION,
      payload: action.payload,
    });
  });

  builder.addCase(fetchUserCurrentInfoAsyncThunk.pending, (apiState) => {
    apiManagementSlice.caseReducers.setFetchUserCurrentInfoApiPendingStateAction(
      apiState
    );
  });

  builder.addCase(
    fetchUserCurrentInfoAsyncThunk.fulfilled,
    (apiState, action) => {
      apiManagementSlice.caseReducers.setFetchUserCurrentInfoApiSuccessStateAction(
        apiState,
        {
          type: ACTION_TYPE.SET_FETCH_USER_CURRENT_INFO_API_SUCCESS_STATE_ACTION,
          payload: action.payload,
        }
      );
    }
  );

  builder.addCase(
    fetchUserCurrentInfoAsyncThunk.rejected,
    (apiState, action) => {
      apiManagementSlice.caseReducers.setFetchUserCurrentInfoApiFailedStateAction(
        apiState,
        {
          type: ACTION_TYPE.SET_FETCH_USER_CURRENT_INFO_API_FAILED_STATE_ACTION,
          payload: action.payload,
        }
      );
    }
  );

  builder.addCase(fetchPaymentPricingGroupsAsyncThunk.pending, (apiState) => {
    apiManagementSlice.caseReducers.setFetchPaymentPricingGroupsApiPendingStateAction(
      apiState
    );
  });

  builder.addCase(
    fetchPaymentPricingGroupsAsyncThunk.fulfilled,
    (apiState, action) => {
      apiManagementSlice.caseReducers.setFetchPaymentPricingGroupsApiSuccessStateAction(
        apiState,
        {
          type: ACTION_TYPE.SET_FETCH_PAYMENT_PRICING_GROUPS_API_SUCCESS_STATE_ACTION,
          payload: action.payload,
        }
      );
    }
  );

  builder.addCase(
    fetchPaymentPricingGroupsAsyncThunk.rejected,
    (apiState, action) => {
      apiManagementSlice.caseReducers.setFetchPaymentPricingGroupsApiFailedStateAction(
        apiState,
        {
          type: ACTION_TYPE.SET_FETCH_PAYMENT_PRICING_GROUPS_API_FAILED_STATE_ACTION,
          payload: action.payload,
        }
      );
    }
  );

  builder.addCase(fetchCastDisplaysAsyncThunk.pending, (apiState) => {
    apiManagementSlice.caseReducers.setFetchCastDisplaysApiPendingStateAction(
      apiState
    );
  });

  builder.addCase(fetchCastDisplaysAsyncThunk.fulfilled, (apiState, action) => {
    apiManagementSlice.caseReducers.setFetchCastDisplaysApiSuccessStateAction(
      apiState,
      {
        type: ACTION_TYPE.SET_FETCH_CAST_DISPLAYS_API_SUCCESS_STATE_ACTION,
        payload: action.payload,
      }
    );
  });

  builder.addCase(fetchCastDisplaysAsyncThunk.rejected, (apiState, action) => {
    apiManagementSlice.caseReducers.setFetchCastDisplaysApiFailedStateAction(
      apiState,
      {
        type: ACTION_TYPE.SET_FETCH_CAST_DISPLAYS_API_FAILED_STATE_ACTION,
        payload: action.payload,
      }
    );
  });
};
