import { createSelector } from "reselect";
import { RootState } from "../store";
import { AuthStateType, SignedInModeType } from "./states";

/**
 * authを全て取得する
 * @param state state
 * @returns auth
 */
export const authSelector = (state: RootState): AuthStateType => state.auth;

/**
 * トークンを取得する
 */
export const authTokenSelector = createSelector(authSelector, (auth) => {
  return auth.authToken;
});

/**
 * ログインフラグを取得する
 */
export const signedInSelector = createSelector(
  authSelector,
  (auth): boolean => {
    return auth.signedIn;
  }
);

/**
 * リダイレクト先情報を取得する
 */
export const redirectReducer = createSelector(authSelector, (auth): string => {
  return auth.redirect;
});

/**
 * サインインモードを取得
 */
export const signedInModeSelector = createSelector(
  authSelector,
  (auth): SignedInModeType => {
    return auth.signedInMode;
  }
);
