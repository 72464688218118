import { Input, InputProps, useTheme } from "@chakra-ui/react";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

export type Props = {
  inputProps: InputProps;
  formRegisters?: UseFormRegisterReturn;
};
/**
 * ベースインプット
 * @param inputProps InputProps
 * @param formRegisters formバリデーション対象登録用
 * @returns ベースインプット
 */
export const BaseInput: React.FC<Props> = ({ inputProps, formRegisters }) => {
  const theme = useTheme();
  return (
    <Input
      textColor={"custom.text.main"}
      borderColor={"custom.primary.main"}
      focusBorderColor={"custom.primary.dark"}
      errorBorderColor={"custom.danger.main"}
      _selection={{ background: "custom.selection" }}
      _autofill={{
        // 入力補助使用時のスタイル維持
        boxShadow: `0 0 0 1000px ${theme.colors.custom.background} inset`,
        WebkitTextFillColor: theme.colors.custom.text.main,
      }}
      {...inputProps}
      {...formRegisters}
    ></Input>
  );
};
