import { MenuDividerProps } from "@chakra-ui/react";
import React from "react";
import { BaseMenuDivider } from "./BaseMenuDivider";

export type Props = {
  menuDividerProps?: MenuDividerProps;
};

/**
 * メニューグループ
 * @param menuDividerProps MenuDividerProps
 * @returns メニューグループ
 */
export const MenuDivider: React.FC<Props> = ({ menuDividerProps }) => {
  return <BaseMenuDivider {...menuDividerProps} />;
};
