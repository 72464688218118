import { UserResponseType } from "services/models/userDataTypes";
import { UserBankResponseType } from "../../../services/models/userDataTypes";
import {
  UserPointResponseType,
  UserSettingResponseType,
} from "../../../services/models/userDataTypes";
import {
  UserAccountResponseType,
  UserProfileResponseType,
} from "../../../services/models/userDataTypes";
import {
  UserAccountStateType,
  UserBankStateType,
  UserPointStateType,
  UserProfileStateType,
  UserSettingStateType,
  UserStateType,
} from "./states";

export const convertAccountResponseToState = (
  response: UserAccountResponseType
): UserAccountStateType => {
  const result: UserAccountStateType = {
    userId: response.user_id,
    email: response.email,
    consecutiveLoginDay: response.consecutive_login_day,
    summaryLoginDay: response.summary_login_day,
    isStaff: response.is_staff,
    isActive: response.is_active,
    tab: response.tab
      ? response.tab.map((tab) => {
          return { tabId: tab.tab_id, tabName: tab.tab_name };
        })
      : null,
    alliance: response.alliance
      ? {
          allianceId: response.alliance.alliance_id,
          allianceName: response.alliance.alliance_name,
        }
      : null,
    createdAt: response.created_at,
  };
  return result;
};

export const convertProfileResponseToState = (
  response: UserProfileResponseType
): UserProfileStateType => {
  const result: UserProfileStateType = {
    username: response.username,
    profileImage: response.profile_image,
    gender: response.gender
      ? {
          genderId: response.gender.gender_id,
          genderName: response.gender.gender_name,
        }
      : null,
    birthday: response.birthday,
    profileText: response.profile_text,
    countFollower: response.count_follower,
    countFollowee: response.count_followee,
    countReview: response.count_review,
    summaryReview: response.summary_review,
    averageReview: response.average_review,
  };
  return result;
};

export const convertPointResponseToState = (
  response: UserPointResponseType
): UserPointStateType => {
  const result: UserPointStateType = {
    firstPoint: response.first_point,
    secondPoint: response.second_point,
  };
  return result;
};

export const convertSettingResponseToState = (
  response: UserSettingResponseType
): UserSettingStateType => {
  const result: UserSettingStateType = {
    isPushAddTodo: response.is_push_add_todo,
    isPushAgainTodo: response.is_push_again_todo,
    isPushFollow: response.is_push_follow,
    isPushDownload: response.is_push_download,
    isPushNews: response.is_push_news,
    isEmailNews: response.is_email_news,
    isHideFollowee: response.is_hide_followee,
  };
  return result;
};

export const convertBankResponseToState = (
  response: UserBankResponseType
): UserBankStateType => {
  const result: UserBankStateType = {
    firstName: response.first_name,
    firstNameKana: response.first_name_kana,
    lastName: response.last_name,
    lastNameKana: response.last_name_kana,
    bankName: response.bank_name,
    bankAccountType: response.bank_account_type,
    bankBranch_code: response.bank_branch_code,
    bankAccountNumber: response.bank_account_number,
    maskBankAccountNumber: response.mask_bank_account_number,
    address: response.address,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
  return result;
};

export const convertUserResponseToState = (
  response: UserResponseType
): UserStateType => {
  const result: UserStateType = {
    account: convertAccountResponseToState(response.account),
    profile: convertProfileResponseToState(response.profile),
    point: convertPointResponseToState(response.point),
    setting: convertSettingResponseToState(response.setting),
    bank: response.bank ? convertBankResponseToState(response.bank) : null,
  };
  return result;
};
