import { request } from "plugins/axios/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "../common/asyncThunkConfig";
import { isFailedResponse } from "plugins/axios/utils";
import { CastDisplaysResponseType } from "services/models/castDisplays";

/**
 * 表示用キャスト一覧取得APIを非同期実行
 */
export const fetchCastDisplaysAsyncThunk = createAsyncThunk<
  CastDisplaysResponseType,
  undefined,
  AsyncThunkConfig
>("cast/castDisplays", async (_, { rejectWithValue }) => {
  const response = await request<CastDisplaysResponseType, unknown, unknown>(
    "GET",
    "CAST_DISPLAYS",
    undefined,
    undefined
  );

  if (isFailedResponse(response)) {
    return rejectWithValue(response);
  } else {
    return response.data;
  }
});
