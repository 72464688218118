import { ImageProps, Img } from "@chakra-ui/react";
import React from "react";

export type Props = {
  imageProps: ImageProps;
};
/**
 * ベースイメージ
 * @param imageProps ImageProps
 * @returns 画像
 */
export const BaseImg: React.FC<Props> = ({ imageProps }) => {
  return (
    <Img _selection={{ background: "custom.selection" }} {...imageProps}></Img>
  );
};
