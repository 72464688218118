import { ColorStage } from "./types";

export const LIGHT_THEME_TEXT: ColorStage = {
  xxLight: "#BEC2C5",
  xLight: "#7E838B",
  light: "#61656B",
  main: "#2E3033",
  dark: "#27282B",
  xDark: "#1D1E20",
  xxDark: "#0A0A0B",
  contrastText: "#FFFFFF",
};

export const DARK_THEME_TEXT: ColorStage = {
  xxLight: "#FFFFFF",
  xLight: "#F5F5F5",
  light: "#F0F0F0",
  main: "#EBEBEB",
  dark: "#E1E1E1",
  xDark: "#D6D6D6",
  xxDark: "#C2C2C2",
  contrastText: "#464646",
};
