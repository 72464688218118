import { FormControl } from "components/uiParts/formControl/FormControl";
import { FormLabel } from "components/uiParts/formLabel/FormLabel";
import { Text } from "components/uiParts/text/Text";
import { EmailInput } from "components/uiParts/input/EmailInput";
import { FormErrorMessage } from "components/uiParts/formErrorMessage/FormErrorMessage";
import { WORD } from "constants/word";
import { FormState, UseFormRegisterReturn } from "react-hook-form";
import { FormControlProps } from "@chakra-ui/react";

export type Props = {
  formState: FormState<{ email: string }>;
  register: UseFormRegisterReturn;
  formControlProps?: FormControlProps;
};

/**
 * react-hook-formでフォームバリデーションを行うメールアドレス用FormControl
 * @param formState formState
 * @param register form管理登録用パラメータ
 * @param formControlProps: FormControlProps
 * @returns react-hook-formで管理されるFormControl
 */
export const EmailFormControl: React.FC<Props> = ({
  formState,
  register,
  formControlProps,
}) => {
  return (
    <FormControl
      formControlProps={{
        isInvalid: !!formState.errors.email,
        ...formControlProps,
      }}
    >
      <FormLabel>
        <Text
          textProps={{
            fontSize: { base: "13px", sm: "17px" },
          }}
        >
          {WORD.EMAIL}
        </Text>
      </FormLabel>
      <EmailInput
        formRegisters={register}
        inputProps={{ fontSize: { base: "12px", sm: "16px" } }}
      />
      <FormErrorMessage>
        {formState.errors.email && formState.errors.email.message}
      </FormErrorMessage>
    </FormControl>
  );
};
