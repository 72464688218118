import { Link, LinkProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  linkProps?: LinkProps;
  children: ReactNode;
};

/**
 * リンク
 * @param linkProps LinkProps
 * @param children  ReactNode
 * @returns リンク
 */
export const BaseChakraLink: React.FC<Props> = ({ linkProps, children }) => {
  return (
    <Link
      color={"custom.link"}
      _selection={{ background: "custom.selection" }}
      {...linkProps}
    >
      {children}
    </Link>
  );
};
