import { EndPointType, END_POINT } from "services/api/endPoints";
import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosError,
} from "axios";
import { MethodType, ErrorResponse } from "./type";
import { handleAxiosError } from "./utils";
import { SENTENCE } from "constants/sentence";

/**
 * statusCodeを削除したカスタムエラーレスポンスの型を定義
 */
type CustomErrorResponse = Omit<ErrorResponse, "statusCode">;

/**
 * axiosライブラリを使用してHttpRequestを行う
 * @param method method指定
 * @param endPoint エンドポイントを指定
 * @param params クエリパラメータ
 * @param data リクエストパラメータ
 * @param headers ヘッダー情報
 * @param customErrorResponse エラーレスポンスのカスタマイズ
 */
export const request = async <T, U, V>(
  method: MethodType,
  endPoint: EndPointType,
  params?: U,
  data?: V,
  headers?: AxiosRequestHeaders,
  customErrorResponse?: CustomErrorResponse
): Promise<AxiosResponse<T> | ErrorResponse> => {
  const options: AxiosRequestConfig = {
    url: END_POINT[endPoint],
    method: method,
    params: params,
    data: data,
    headers: headers,
  };
  try {
    return await axios(options)
      .then((response: AxiosResponse<T>) => response)
      .catch((error: AxiosError<ErrorResponse>): ErrorResponse => {
        let errorResponse = {
          ...customErrorResponse,
          ...handleAxiosError(error),
        };
        if (
          error.response &&
          error.response.status &&
          error.response.data["statusCode"] !== undefined
        ) {
          errorResponse.statusCode = error.response.data.statusCode;
        }
        if (
          error.response &&
          error.response.status &&
          error.response.data["message"] !== undefined
        ) {
          errorResponse.message = error.response.data.message;
        }
        if (
          error.response &&
          error.response.status &&
          error.response.data["detail"] !== undefined
        ) {
          errorResponse.detail = error.response.data.detail;
        }
        return errorResponse;
      });
  } catch (err) {
    return {
      statusCode: SENTENCE.DEFAULT.API_ERROR.STATUS_CODE,
      message: SENTENCE.DEFAULT.API_ERROR.MESSAGE,
      detail: SENTENCE.DEFAULT.API_ERROR.DETAIL,
    };
  }
};
