import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchUserCurrentInfoAsyncThunk } from "./asyncThunks";
import { ACTION_TYPE } from "./reducers";
import { userSlice } from "./slice";
import { UserStateType } from "./states";

/**
 * ExtraReducersを定義
 */
export const extraReducers = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(fetchUserCurrentInfoAsyncThunk.fulfilled, (state, action) => {
    userSlice.caseReducers.setUserSuccessStateAction(state, {
      type: ACTION_TYPE.SET_USER_SUCCESS_STATE_ACTION,
      payload: action.payload,
    });
  });
};
