import { ColorStage } from "./types";

export const LIGHT_THEME_DANGER: ColorStage = {
  xxLight: "#FF725C",
  xLight: "#FF4E33",
  light: "#FF2B0A",
  main: "#E01E00",
  dark: "#CC1B00",
  xDark: "#B81800",
  xxDark: "#A31600",
  contrastText: "#FFFFFF",
};

export const DARK_THEME_DANGER: ColorStage = {
  xxLight: "#FF725C",
  xLight: "#FF4E33",
  light: "#FF2B0A",
  main: "#E01E00",
  dark: "#CC1B00",
  xDark: "#B81800",
  xxDark: "#A31600",
  contrastText: "#FFFFFF",
};
