import { MenuButtonProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseMenuButton } from "./BaseMenuButton";

export type Props = {
  menuButtonProps?: MenuButtonProps;
  children: ReactNode;
};

/**
 * メニューボタン
 * @param menuButtonProps MenuButtonProps
 * @param children  ReactNode
 * @returns メニューボタン
 */
export const MenuButton: React.FC<Props> = ({ menuButtonProps, children }) => {
  return (
    <BaseMenuButton
      menuButtonProps={{
        ...menuButtonProps,
      }}
    >
      {children}
    </BaseMenuButton>
  );
};
