import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchPaymentPricingGroupsAsyncThunk } from "./asyncThunks";
import { ACTION_TYPE } from "./reducers";
import { stripeManagementSlice } from "./slice";
import { StripeManagementStateType } from "./states";

/**
 * ExtraReducersを定義
 */
export const extraReducers = (
  builder: ActionReducerMapBuilder<StripeManagementStateType>
) => {
  builder.addCase(
    fetchPaymentPricingGroupsAsyncThunk.fulfilled,
    (state, action) => {
      stripeManagementSlice.caseReducers.setPricingGroupsStateAction(state, {
        type: ACTION_TYPE.SET_PRICING_GROUPS_STATE_ACTION,
        payload: action.payload,
      });
    }
  );
};
