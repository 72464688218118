import { Avatar, AvatarProps } from "@chakra-ui/react";
import React from "react";

export type Props = {
  avatarProps: AvatarProps;
};
/**
 * アバター
 * @param avatarProps AvatarProps
 * @returns div要素
 */
export const BaseAvatar: React.FC<Props> = ({ avatarProps }) => {
  return <Avatar {...avatarProps}></Avatar>;
};
