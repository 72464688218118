import { FormControlProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseFormControl } from "./BaseFormControl";

export type Props = {
  formControlProps?: FormControlProps;
  children: ReactNode;
};
/**
 * FormLabelコンポーネントとInputコンポーネントを囲うために使う
 * @param formControlProps FromControlProps
 * @param children ReactNode
 * @returns role属性を持ったdiv要素
 */

export const FormControl: React.FC<Props> = ({
  formControlProps,
  children,
}) => {
  return (
    <BaseFormControl formControlProps={{ ...formControlProps }}>
      {children}
    </BaseFormControl>
  );
};
