// ONERECO APIのベースURLを環境変数から取得
export const BASE = process.env.REACT_APP_ONERECO_API_BASE_URL;

// エンドポイント
export const END_POINT = {
  // master
  MASTER_BAD_REVIEW_OUTLINES: BASE + "api/v2/master/bad_review_outlines/",
  MASTER_BANNERS: BASE + "api/v2/master/banners/",
  // auth
  SIGN_IN: BASE + "api/v2/auth/sign_in/",
  // user
  USER_CURRENT_INFO: BASE + "api/v2/user/current_info/",
  // cast
  CAST_DISPLAYS: BASE + "api/v2/cast/displays/",
  // payment
  PAYMENT_PRICING_GROUPS: BASE + "api/v2/payment/pricing_groups/",
};

export type EndPointType = keyof typeof END_POINT;
