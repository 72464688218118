import { FlexProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BaseFlex } from "./BaseFlex";

export type Props = {
  flexProps?: FlexProps;
  children: ReactNode;
};
/**
 * 要素を縦並び・横軸に対して中央揃えにする
 * @param flexProps FlexProps
 * @param children unitParts
 * @returns flex　縦並び/横軸に対して中央揃え
 */

export const ColumnRowCenterFlex: React.FC<Props> = ({
  flexProps,
  children,
}) => {
  return (
    <BaseFlex
      flexProps={{
        flexDirection: "column",
        align: "center",
        justify: "center",
        ...flexProps,
      }}
    >
      {children}
    </BaseFlex>
  );
};
