import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useAppSelector } from "plugins/reduxToolkit/hooks";
import { apiManagementSelectors } from "plugins/reduxToolkit/apiManagement";
import { generateToastOptions } from "components/uiParts/toast/generateToastOptions";
import { authSelectors } from "plugins/reduxToolkit/auth";
import { SENTENCE } from "constants/sentence";

export type Props = {
  children: React.ReactNode;
};

/**
 * ステートを監視して条件によってトーストを表示する
 * @param children children
 * @returns Wrapper
 */
export const ObserverToastWrapper: React.FC<Props> = ({ children }) => {
  const toast = useToast();
  const apiStatus = useAppSelector(
    apiManagementSelectors.apiManagementSelector
  );
  const isMaintenanceMode = useAppSelector(
    apiManagementSelectors.isMaintenanceModeSelector
  );
  const signInMode = useAppSelector(authSelectors.signedInModeSelector);

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }
    if (
      apiStatus.signInApiStatus.status === "SUCCESS" &&
      signInMode === "SIGN_IN"
    ) {
      toast(
        generateToastOptions(SENTENCE.TOAST.TITLE.SIGN_IN_SUCCESS, {
          status: "success",
        })
      );
    }
    if (apiStatus.signInApiStatus.status === "FAILED") {
      toast(
        generateToastOptions(SENTENCE.TOAST.TITLE.SIGN_IN_FAILED, {
          status: "error",
          description: SENTENCE.TOAST.DESCRIPTION.SIGN_IN_FAILED,
        })
      );
    }
  }, [
    isMaintenanceMode,
    apiStatus.signInApiStatus.status,
    apiStatus.signInApiStatus.error,
    signInMode,
    toast,
  ]);

  useEffect(() => {
    if (isMaintenanceMode) {
      toast(
        generateToastOptions(SENTENCE.TOAST.TITLE.MAINTENANCE_MODE, {
          status: "error",
          description: SENTENCE.TOAST.DESCRIPTION.MAINTENANCE_MODE,
        })
      );
    }
  }, [isMaintenanceMode, toast]);

  return <>{children}</>;
};
