import { PrimaryButton } from "components/uiParts/button/PrimaryButton";
import { WORD } from "constants/word";
import React, { useCallback, useEffect } from "react";
import { SignInFormResolver } from "./SignInFormResolver";
import { useForm } from "react-hook-form";
import { EmailFormControl } from "../formControl/EmailFormControl";
import { PasswordFormControl } from "../formControl/PasswordFormControl";
import { SignInRequestType } from "services/models/signInType";
import { useAppSelector, useAppDispatch } from "plugins/reduxToolkit/hooks";
import {
  authActions,
  authAsyncThunks,
  authSelectors,
} from "plugins/reduxToolkit/auth";
import { apiManagementSelectors } from "plugins/reduxToolkit/apiManagement";
import { useNavigate } from "@tanstack/react-location";
import { Text } from "components/uiParts/text/Text";
import { SENTENCE } from "constants/sentence";

export type SignInFormValues = {
  email: string;
  password: string;
};

/**
 * サインインフォーム
 * @returns サインインフォームコンポーネント
 */
export const SignInForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signInApiStatus = useAppSelector(
    apiManagementSelectors.signInApiStatusSelector
  );
  const authToken = useAppSelector(authSelectors.authTokenSelector);
  const redirect = useAppSelector(authSelectors.redirectReducer);
  const { register, handleSubmit, formState, watch } =
    useForm<SignInFormValues>({
      resolver: SignInFormResolver,
    });

  useEffect(() => {
    // 認証済みユーザをトップ画面へリダイレクト
    if (authToken) {
      navigate({ to: redirect });
      dispatch(authActions.resetRedirect());
    }
  }, [authToken, redirect, navigate, dispatch]);

  const onSubmit = useCallback(() => {
    const userData: SignInRequestType = {
      password: watch("password"),
      email: watch("email"),
    };
    dispatch(authAsyncThunks.signInAsyncThunk(userData));
  }, [dispatch, watch]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%", maxWidth: "600px" }}
    >
      {/* メールアドレス */}
      <EmailFormControl
        formState={formState}
        register={register("email")}
        formControlProps={{ paddingBottom: { base: "24px", sm: "48px" } }}
      />

      {/* パスワード */}
      <PasswordFormControl
        formState={formState}
        register={register("password")}
        formControlProps={{ paddingBottom: "16px" }}
      />
      <Text
        textProps={{
          marginBottom: "24px",
          fontSize: { base: "10px", sm: "12px" },
          color: "custom.text.light",
        }}
      >
        {SENTENCE.FORGET_PASSWORD}
        <br />
        {SENTENCE.FORGET_PASSWORD_ACTION}
      </Text>
      {/* サインインボタン */}
      <PrimaryButton
        buttonProps={{
          isLoading: signInApiStatus.status === "PENDING",
          type: "submit",
          width: "100%",
        }}
        title={WORD.SIGN_IN_UPPER_CAMEL}
      />
    </form>
  );
};
