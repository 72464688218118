import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  boxProps: BoxProps;
  children: ReactNode;
};
/**
 * ベースボックス
 * @param boxProps BoxProps
 * @param children ReactNode
 * @returns div要素
 */
export const BaseBox: React.FC<Props> = ({ boxProps, children }) => {
  return <Box {...boxProps}>{children}</Box>;
};
