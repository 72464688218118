export type UserStateType = {
  account: UserAccountStateType | null;
  profile: UserProfileStateType | null;
  point: UserPointStateType | null;
  setting: UserSettingStateType | null;
  bank: UserBankStateType | null;
};

export type UserAccountStateType = {
  userId: string;
  email: string;
  consecutiveLoginDay: number;
  summaryLoginDay: number;
  isStaff: boolean;
  isActive: boolean;
  tab:
    | {
        tabId: number;
        tabName: string;
      }[]
    | null;
  alliance: {
    allianceId: number;
    allianceName: string;
  } | null;
  createdAt: string;
};
export type UserProfileStateType = {
  username: string;
  profileImage: string;
  gender: {
    genderId: number;
    genderName: string;
  } | null;
  birthday: string | null;
  profileText: string | null;
  countFollower: number;
  countFollowee: number;
  countReview: number;
  summaryReview: number;
  averageReview: string;
};
export type UserPointStateType = {
  firstPoint: number;
  secondPoint: number;
};
export type UserSettingStateType = {
  isPushAddTodo: boolean;
  isPushAgainTodo: boolean;
  isPushFollow: boolean;
  isPushDownload: boolean;
  isPushNews: boolean;
  isEmailNews: boolean;
  isHideFollowee: boolean;
};
export type UserBankStateType = {
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
  bankName: string;
  bankAccountType: string;
  bankBranch_code: string;
  bankAccountNumber: string;
  maskBankAccountNumber: string;
  address: string;
  createdAt: string;
  updatedAt: string;
};

/**
 * 初期化ステート
 */
export const initialState: UserStateType = {
  account: null,
  profile: null,
  point: null,
  setting: null,
  bank: null,
};

/**
 * リセットステート
 */
export const resetState: UserStateType = {
  account: null,
  profile: null,
  point: null,
  setting: null,
  bank: null,
};
