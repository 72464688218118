import { AwsRum, AwsRumConfig } from "aws-rum-web";

const config: AwsRumConfig = {
  sessionSampleRate: 1,
  guestRoleArn: process.env.REACT_APP_CLOUD_WATCH_RUN_GUEST_ROLE_ARM,
  identityPoolId: process.env.REACT_APP_CLOUD_WATCH_RUN_IDENTITY_POOL_ID,
  endpoint: "https://dataplane.rum.ap-northeast-1.amazonaws.com",
  telemetries: ["performance", "errors", "http"],
  allowCookies: true,
  enableXRay: process.env.REACT_APP_CLOUD_WATCH_RUN_ENABLE_X_RAY === "true",
};

const APPLICATION_ID =
  process.env.REACT_APP_CLOUD_WATCH_RUN_APPLICATION_ID ?? "";
const APPLICATION_VERSION: string = "1.0.0";
const APPLICATION_REGION: string = "ap-northeast-1";

/**
 * CloudWatchRunのカスタムイベントを送信するための設定ファイル
 */
export const awsRum: AwsRum = new AwsRum(
  APPLICATION_ID,
  APPLICATION_VERSION,
  APPLICATION_REGION,
  config
);
