/**
 * 0-520px: スマホサイズ
 * 520px-980px: タブレットサイズ
 * 980px-1200px: PCサイズ
 * 1200px-1536px: WIDE-PCサイズ
 * 1536px-2000px: それ以上
 *
 * ============================
 *
 * 使用方法
 * base: xxx = 0-520pxのスタイル
 * sm: xxx = 520-980pxのスタイル
 * md: xxx = 980-1200pxのスタイル
 * lg: xxx = 1200px-1536px
 * 2xl: xxx = 1536px-2000pxのスタイル
 */

export const BREAK_POINT = {
  SM: "520px",
  MD: "980px",
  LG: "1200px",
  XL: "1536px",
  XXL: "2000px",
};

export const breakpoints = {
  sm: BREAK_POINT.SM,
  md: BREAK_POINT.MD,
  lg: BREAK_POINT.LG,
  xl: BREAK_POINT.XL,
  "2xl": BREAK_POINT.XXL,
};
