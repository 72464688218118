import { CastDisplaysResponseType } from "services/models/castDisplays";
import { CastDisplaysStateType, CastDisplayStateType } from "./states";

export const convertCastDisplaysResponseToState = (
  response: CastDisplaysResponseType
): CastDisplaysStateType => {
  const result: CastDisplaysStateType = response.cast_displays.map(
    (display): CastDisplayStateType => {
      return {
        userId: display.user_id,
        profileImage: display.profile_image,
        username: display.username,
      };
    }
  );
  return result;
};
