import { createAsyncThunk } from "@reduxjs/toolkit";
import { SENTENCE } from "constants/sentence";
import { request } from "plugins/axios/request";
import { isFailedResponse, notExistTokenError } from "plugins/axios/utils";
import { UserResponseType } from "services/models/userDataTypes";
import { generateAuthorizationHeader } from "utils/generateAuthorizationHeader";
import { AsyncThunkConfig } from "../common/asyncThunkConfig";

/**
 * ユーザー情報取得のAPIを非同期実行
 */
export const fetchUserCurrentInfoAsyncThunk = createAsyncThunk<
  UserResponseType,
  string | undefined,
  AsyncThunkConfig
>("user/current_info", async (token, { rejectWithValue, getState }) => {
  const authToken: string | null = token ?? getState().auth.authToken;
  if (!authToken) {
    return rejectWithValue(notExistTokenError);
  }
  const response = await request<UserResponseType, unknown, unknown>(
    "GET",
    "USER_CURRENT_INFO",
    undefined,
    undefined,
    generateAuthorizationHeader(authToken),
    {
      message: SENTENCE.API_ERROR.SIGN_IN_MESSAGE,
      detail: SENTENCE.API_ERROR.SIGN_IN_DETAIL,
    }
  );

  if (isFailedResponse(response)) {
    return rejectWithValue(response);
  } else {
    return response.data;
  }
});
