export type CastDisplayStateType = {
  userId: string;
  profileImage: string;
  username: string;
};

export type CastDisplaysStateType = CastDisplayStateType[];

export type CastStateType = {
  displays: CastDisplaysStateType;
};

/**
 * 初期化ステート
 */
export const initialState: CastStateType = {
  displays: [],
};

/**
 * リセットステート
 */
export const resetState: CastStateType = {
  displays: [],
};
