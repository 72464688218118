import { UseToastOptions } from "@chakra-ui/react";

/**
 * トーストのオプションを生成する
 * @param title タイトル
 * @param options カスタムオプション
 * @returns トースト設定情報
 */
export const generateToastOptions = (
  title: string,
  options?: UseToastOptions
): UseToastOptions => {
  return {
    title: title,
    position: "top-right",
    isClosable: true,
    duration: 4500,
    ...options,
  };
};
