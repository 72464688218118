import { MenuDivider, MenuDividerProps } from "@chakra-ui/react";
import React from "react";

export type Props = {
  menuDividerProps?: MenuDividerProps;
};

/**
 * メニューグループ
 * @param menuDividerProps MenuDividerProps
 * @returns メニューグループ
 */
export const BaseMenuDivider: React.FC<Props> = ({ menuDividerProps }) => {
  return (
    <MenuDivider backgroundColor={"custom.gray.light"} {...menuDividerProps} />
  );
};
