import { castSelectors, castThunks } from "plugins/reduxToolkit/cast";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../plugins/reduxToolkit/hooks";
import { Img } from "components/uiParts/img/Img";
import { Box } from "components/uiParts/box/Box";
import { apiManagementSelectors } from "plugins/reduxToolkit/apiManagement";
import { PageLoading } from "../pageLoading/PageLoading";
import { opacityStyle } from "../../uiParts/styles/hover";
import { FadeInMotionDiv } from "components/motions/FadeInMotionDiv";
import { useEffect } from "react";

/**
 * 表示用のキャストをグリッドで表示する
 * @returns CastGridDisplays
 */
export const CastGridDisplays: React.FC = () => {
  const castDisplays = useAppSelector(castSelectors.castDisplaysRandomSorted);
  const fetchCastDisplaysStatus = useAppSelector(
    apiManagementSelectors.fetchCastDisplaysStatusSelector
  );
  const dispatch = useAppDispatch();
  const castDisplaysApiStatus = useAppSelector(
    apiManagementSelectors.fetchCastDisplaysStatusSelector
  );

  useEffect(() => {
    if (castDisplaysApiStatus.status === "WAITING")
      dispatch(castThunks.fetchCastDisplaysAsyncThunk());
  }, [dispatch, castDisplaysApiStatus]);

  if (fetchCastDisplaysStatus.status === "SUCCESS") {
    return (
      <Box
        boxProps={{
          padding: { base: "8px", sm: "24px" },
        }}
      >
        {/* キャスト画像をグリッド配置 */}
        <Box
          boxProps={{
            display: "grid",
            gap: "16px",
            justifyItems: "center",
            alignItems: "center",
            gridTemplateColumns: {
              base: "1fr 1fr",
              sm: "1fr 1fr 1fr",
              md: "1fr 1fr 1fr 1fr",
            },
          }}
        >
          {castDisplays.map((cast, index) => {
            return (
              <FadeInMotionDiv
                duration={index * 0.2}
                key={`${cast.userId}${index}`}
              >
                <Img
                  imageProps={{
                    src: cast.profileImage,
                    borderRadius: "20px",
                    _hover: opacityStyle,
                  }}
                />
              </FadeInMotionDiv>
            );
          })}
        </Box>
      </Box>
    );
  } else {
    return <PageLoading />;
  }
};
