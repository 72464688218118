import { createAsyncThunk } from "@reduxjs/toolkit";
import { SENTENCE } from "constants/sentence";
import { request } from "plugins/axios/request";
import { isFailedResponse } from "plugins/axios/utils";
import {
  SignInRequestType,
  SignInResponseType,
} from "services/models/signInType";
import { AsyncThunkConfig } from "../common/asyncThunkConfig";
import { userAsyncThunks } from "../user";
import { isString } from "../../../utils/isString";
import { generateAuthorizationHeader } from "utils/generateAuthorizationHeader";
import { authActions } from "./index";
import { apiManagementActions } from "../apiManagement/index";
import { sendLog } from "log/sendLog";

/**
 * サインインAPIを非同期実行
 */
export const signInAsyncThunk = createAsyncThunk<
  SignInResponseType,
  SignInRequestType | string,
  AsyncThunkConfig
>("auth/signIn", async (parameter, { rejectWithValue, dispatch }) => {
  let response = undefined;
  let isReSignIn = false;
  if (isString(parameter)) {
    // 再サインインユーザ
    dispatch(authActions.setSignedInMode("RE_SIGN_IN"));
    response = await request<SignInResponseType, unknown, unknown>(
      "POST",
      "SIGN_IN",
      undefined,
      undefined,
      generateAuthorizationHeader(parameter),
      {
        message: SENTENCE.API_ERROR.SIGN_IN_MESSAGE,
        detail: SENTENCE.API_ERROR.SIGN_IN_DETAIL,
      }
    );
    isReSignIn = true;
  } else {
    // サインイン画面からのサインインユーザ
    dispatch(authActions.setSignedInMode("SIGN_IN"));
    response = await request<SignInResponseType, unknown, SignInRequestType>(
      "POST",
      "SIGN_IN",
      undefined,
      {
        email: parameter.email,
        password: parameter.password,
      },
      undefined,
      {
        message: SENTENCE.API_ERROR.SIGN_IN_MESSAGE,
        detail: SENTENCE.API_ERROR.SIGN_IN_DETAIL,
      }
    );
    isReSignIn = false;
  }

  if (isFailedResponse(response)) {
    if (response.statusCode === "503") {
      dispatch(apiManagementActions.setMaintenanceModeOn());
    }
    dispatch(authActions.setSignedInMode(null));
    return rejectWithValue(response);
  } else {
    if (isReSignIn) {
      sendLog("re-sign-in-success", { authToken: response.data.auth_token });
    } else {
      sendLog("sign-in-success", { authToken: response.data.auth_token });
    }
    dispatch(
      userAsyncThunks.fetchUserCurrentInfoAsyncThunk(response.data.auth_token)
    );
    return response.data;
  }
});
