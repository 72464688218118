import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./reducers";
import { initialState } from "./states";
import { extraReducers } from "./extraReducers";

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers,
  extraReducers,
});
