/**
 * 16進数カラーコード(#xxxxxx)からRGBA形式へ変換する関数
 * @param hex 16進数カラーコード
 * @param alpha 透明度(0-1)
 * @returns RGBAカラーコード
 */
export const convertHexToRgba = (hex: string, alpha: number): string => {
  // カラーコード形式バリデーション
  if (!/^#[0-9A-Fa-f]{6}$/.test(hex) && !/^#[0-9A-Fa-f]{3}$/.test(hex)) {
    throw new Error("不正なカラーコードです");
  }

  if (alpha < 0)
    // データ整形
    alpha = 0;
  if (alpha > 1) alpha = 1;
  if (hex.slice(0, 1) === "#") hex = hex.slice(1);
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((v) => v.repeat(2))
      .join("");
  }

  // int変換(0-255)
  const red = parseInt("0x" + hex.slice(0, 2));
  const green = parseInt("0x" + hex.slice(2, 4));
  const blue = parseInt("0x" + hex.slice(4, 6));

  return `rgba(${[red, green, blue, alpha].join(", ")})`;
};
