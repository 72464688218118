import { ColorStage } from "./types";

export const LIGHT_THEME_SUCCESS: ColorStage = {
  xxLight: "RGBA(222,0, 22, 1)",
  xLight: "#30D98A",
  light: "#20AC6B",
  main: "#198754",
  dark: "#16794B",
  xDark: "#136740",
  xxDark: "#105635",
  contrastText: "#FFFFFF",
};

export const DARK_THEME_SUCCESS: ColorStage = {
  xxLight: "#64E3A7",
  xLight: "#30D98A",
  light: "#20AC6B",
  main: "#198754",
  dark: "#16794B",
  xDark: "#136740",
  xxDark: "#105635",
  contrastText: "#FFFFFF",
};
