import { ColorStage } from "./types";

export const LIGHT_THEME_INFO: ColorStage = {
  xxLight: "#70A5FF",
  xLight: "#478BFF",
  light: "#1F71FF",
  main: "#0052E0",
  dark: "#004BCC",
  xDark: "#003CA3",
  xxDark: "#00348F",
  contrastText: "#FFFFFF",
};

export const DARK_THEME_INFO: ColorStage = {
  xxLight: "#70A5FF",
  xLight: "#478BFF",
  light: "#1F71FF",
  main: "#0052E0",
  dark: "#004BCC",
  xDark: "#003CA3",
  xxDark: "#00348F",
  contrastText: "#FFFFFF",
};
