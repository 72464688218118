import { InputProps } from "@chakra-ui/react";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { BaseInput } from "./BaseInput";

export type Props = {
  inputProps?: InputProps;
  formRegisters?: UseFormRegisterReturn;
};
/**
 * パスワード用のinput要素
 * @param inputProps InputProps
 * @param formRegisters formバリデーション対象登録用
 * @returns input (type:password)
 */
export const PasswordInput: React.FC<Props> = ({
  inputProps,
  formRegisters,
}) => {
  return (
    <BaseInput
      inputProps={{
        size: "lg",
        type: "password",
        variant: "flushed",
        placeholder: "Password",
        ...inputProps,
      }}
      formRegisters={formRegisters}
    ></BaseInput>
  );
};
