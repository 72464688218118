import { createContext, Dispatch } from "react";
import { ThemeAction } from "./actions";
import { LIGHT, ThemeStore } from "./store";

const initialState: ThemeStore = {
  mode: LIGHT,
};

/**
 * Theme用Contextを定義
 */
export const ThemeContext = createContext<{
  state: ThemeStore;
  dispatch: Dispatch<ThemeAction>;
}>({
  state: initialState,
  dispatch: () => initialState,
});
