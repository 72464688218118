import { ReactNode } from "react";
import { Outlet, Router } from "@tanstack/react-location";
import { location } from "./location";
import { routes } from "./routes";

export type Props = {
  children?: ReactNode;
};

/**
 * ReactLocationルーター
 * @param children ReactNode
 */
export const ReactLocationRouter: React.FC<Props> = ({ children }) => {
  return (
    <Router location={location} routes={routes}>
      <Outlet />
    </Router>
  );
};
