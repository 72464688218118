import { MenuButton, MenuButtonProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type Props = {
  menuButtonProps?: MenuButtonProps;
  children: ReactNode;
};

/**
 * メニューボタン
 * @param menuButtonProps MenuButtonProps
 * @param children  ReactNode
 * @returns メニューボタン
 */
export const BaseMenuButton: React.FC<Props> = ({
  menuButtonProps,
  children,
}) => {
  return (
    <MenuButton
      color={"custom.text.main"}
      fontWeight={"bold"}
      padding={{ base: "4px", sm: "8px" }}
      borderRadius={"8px"}
      _hover={{ backgroundColor: "custom.primary.main" }}
      userSelect={"none"}
      fontSize={{ base: "12px", sm: "14px" }}
      {...menuButtonProps}
    >
      {children}
    </MenuButton>
  );
};
