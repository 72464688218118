import { createSelector } from "reselect";
import { ApiStatusType } from "../common/apiStatus";
import { RootState } from "../store";
import { ApiManagementStateType } from "./states";

/**
 * apiManagementを全て取得する
 * @param state state
 * @returns apiManagement
 */
export const apiManagementSelector = (
  state: RootState
): ApiManagementStateType => state.apiManagement;

/**
 * 初期化処理用APIステータスを取得する
 */
export const initializeApiStatusSelector = createSelector(
  apiManagementSelector,
  (apiManagement): ApiStatusType => {
    const targetApiStatuses: ApiStatusType[] = [
      apiManagement.signInApiStatus.status,
      apiManagement.fetchUserCurrentInfoStatus.status,
    ];

    if (targetApiStatuses.every((status) => status === "WAITING")) {
      // 全て未実行
      return "WAITING";
    } else if (targetApiStatuses.some((status) => status === "FAILED")) {
      // どれか一つでも失敗
      return "FAILED";
    } else if (targetApiStatuses.some((status) => status === "PENDING")) {
      // どれか一つでも実行中
      return "PENDING";
    } else {
      // それ以外=全て成功
      return "SUCCESS";
    }
  }
);

/**
 * サインインAPIステータスを取得する
 */
export const signInApiStatusSelector = createSelector(
  apiManagementSelector,
  (apiManagement) => {
    return apiManagement.signInApiStatus;
  }
);

/**
 * ユーザー情報取得APIステータスを取得する
 */
export const fetchUserCurrentInfoApiStatusSelector = createSelector(
  apiManagementSelector,
  (apiManagement) => {
    return apiManagement.fetchUserCurrentInfoStatus;
  }
);

/**
 * 価格テーブル取得APIステータスを取得する
 */
export const fetchPaymentPricingGroupsApiStatusSelector = createSelector(
  apiManagementSelector,
  (apiManagement) => {
    return apiManagement.fetchPaymentPricingGroupsStatus;
  }
);

/**
 * 表示用キャスト取得APIステータスを取得する
 */
export const fetchCastDisplaysStatusSelector = createSelector(
  apiManagementSelector,
  (apiManagement) => {
    return apiManagement.fetchCastDisplaysStatus;
  }
);

/**
 * メンテナンスフラグ取得
 */
export const isMaintenanceModeSelector = createSelector(
  apiManagementSelector,
  (apiManagement): boolean => {
    return apiManagement.isMaintenanceMode;
  }
);
