import React from "react";
import { PersistGate as Container } from "redux-persist/integration/react";
import { persistor } from "./persistor";
import { PageLoading } from "components/unitParts/pageLoading/PageLoading";
import { InitializeWrapper } from "./InitializeWrapper";

export type Props = {
  children: React.ReactNode;
};

/**
 * PersistGate
 * @param children children
 * @returns PersistGate
 */
export const PersistGate: React.FC<Props> = ({ children }) => {
  return (
    <Container loading={<PageLoading />} persistor={persistor}>
      <InitializeWrapper>{children}</InitializeWrapper>
    </Container>
  );
};
